import Jsona from "jsona";
import http from "../../api/http";
import Config from "../../../config";
import { reactTableFilters, reactTableSort } from "../utils";
import { showNotification } from "../smfi/notification";
import _ from 'lodash';
import { fetchAClient } from '../smfi/clients';

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function fetchLoanHistories(loansId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl += loansId.concat('/histories');

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_LOAN_HISTORIES", histories: res });
    
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

// get all loan counts

export function fetchLoanCounts() {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;

  const request = http.get(apiBaseUrl.concat('count'));

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_LOAN_COUNTS", counts: res });
    
      },
      error => {
        console.log("Error Request", error);
      }
    );
  };
}

//   api/v1/loans/list/
export function fetchLoanList(state, instance, type) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOAN_LISTING;

    let params = state
      ? {
        page: {
          size: state.pageSize,
          number: state.page + 1
        },
        sort: reactTableSort(state).pop(),
        filter: reactTableFilters(state)
      }
      : {
        ...Config.defaultMeta
      };
  
    params.module = "loan";
    params.include = "clients,branch,loan_types"

    const request = http.get(apiBaseUrl.concat(type), {
      params
    });
  
    const dataFormatter = new Jsona();
  
    return dispatch => {
      request.then(
        ({ data }) => {
  
          let res = dataFormatter.deserialize(data);
  
          dispatch({
            type: "FETCH_LOANS",
            data: res,
            meta: data.meta
          });
        },
        error => {
          console.log("Error Request", error);
        }
      );
    };
}

export function fetchALoans(loansId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl += loansId

  let params = {};

  params.include = "clients,loan_document"

  const request = http.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    
    dispatch(setLoansLoading(true, "Retreiving loan details..."));

    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_A_LOANS", payload: res[0], loansId: loansId });
        if(!_.isEmpty(res)) dispatch(fetchAClient(res[0].clientId));

      },
      (error) => {
        dispatch(showNotification({ type: 'error', message: error }));
        dispatch({ type: 'ERROR_LOANS', errorMsg: strGeneralError })
      }
    );
  };
}

export function submitLoansForApproval(objFormValues, requiredDocs) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL 
  + process.env.REACT_APP_LOANS.concat('apply');

  let params = {
    data:
    {
      type: "loan",
      attributes:
      {
        ...objFormValues
      }
    }
  }

  const request = http.post(apiBaseUrl, params)

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(({
      data
    }) => {

      let res = dataFormatter.deserialize(data)

      dispatch(uploadDocuments(res.id, objFormValues, requiredDocs))

      dispatch(setLoansLoading(true, "Submitting loan application..."));
      dispatch({ type: 'LOAN_FOR_APPROVAL_SUCCESS', payload: res })
      dispatch(showNotification({ type: "success", message: "Loan application has successfully been created!" }));
      dispatch(fetchLoanCounts());

    },
      (error) => {
        dispatch({
          type: 'ERROR_LOANS',
          errorMsg: error,
          payload: objFormValues
        })

      });
  }
}

export function updateLoansForApproval(objFormValues, loansId, requiredDocs) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl += loansId;

  const request = http.put(apiBaseUrl, {
    data:
    {
      type: "loan",
      attributes:
      {
        ...objFormValues
      }
    }
  })

  return (dispatch) => {

    dispatch(setLoansLoading(true, "Updating loan request..."));

    request.then(({
      data
    }) => {
      
      dispatch({ type: 'LOANS_UPDATED', payload: objFormValues })

      // dispatch(uploadDocuments(loansId, objFormValues, requiredDocs))

      dispatch(showNotification({ type: "success", message: "Loan application has successfully been updated!" }));
      dispatch(fetchALoans(loansId))
      dispatch(fetchLoanCounts());

    },
      (error) => {
        dispatch({
          type: 'ERROR_LOANS',
          errorMsg: error
        })

      });
  }
}

export function setLoansLoading(boolIsLoading = true, loadingMsg = "Proccessing Loan Transaction Request...") {
    return (dispatch) => {
      dispatch({
        type: 'LOANS_LOADING',
        boolIsLoading,
        loadingMsg
      })
    }
}

// upload
export function uploadDocuments(referenceId, objData, fileStringTypes) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl = apiBaseUrl.concat(referenceId).concat('/saveDocuments');

  var formData = new FormData();

  if(Array.isArray(objData.files)){
    for (var key in objData.files) {
      formData.append("files", objData.files[key]);
    }
    for (var fileKey in fileStringTypes) {
      formData.append(fileStringTypes[fileKey].id, fileStringTypes[fileKey].file);
    }

    //build form header
    http.interceptors.request.use(config => {
      config.headers = { "Content-Type": "multipart/form-data" };
      return config;
    });

    const request = http.post(apiBaseUrl, formData);

    const dataFormatter = new Jsona();

    return dispatch => {
      request.then(
        ({ data }) => {
          let res = dataFormatter.deserialize(data)
          console.log("DOCUMENTS UPLOADED", res);
        },
        error => {
          console.log("Error Request", error);
          dispatch({ type: "ERROR_SAVINGS", errorMsg: error });
        }
      );
    };
  }
}
  
export function defaultLoans() {
  return dispatch => {
    dispatch({
      type: "LOANS_LIST_DEFAULT"
    });
  };
}

// loan confirmation

export function showUpdateStatusDialog(updateStatusTo) {
  return dispatch => {
    dispatch({
      type: "SHOW_UPDATE_STATUS_DIALOG",
      updateStatusTo: updateStatusTo
    });
  };
}

export function updateLoanStateTo(updateStatusTo){
  return dispatch => {
    dispatch({
      type: "UPDATE_LOAN_STATE",
      updateStatusTo: updateStatusTo
    });
  };
}

export function closeUpdateStatusDialog() {
  return dispatch => {
    dispatch({
      type: "HIDE_UPDATE_STATUS_DIALOG"
    });
  };
}

export function showUpdateStatusDialogLoading() {
  return dispatch => {
    dispatch({
      type: "SHOW_UPDATE_STATUS_DIALOG_LOADER"
    });
  };
}

export function updateLoansApplication(objFormValues, loansId, loanStatus) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl += loansId.concat('/' + loanStatus);

  const request = http.post(apiBaseUrl, {
    data:
    {
      type: "loan",
      attributes:
      {
        ...objFormValues
      }
    }
  })

  return (dispatch) => {

    dispatch(setLoansLoading(true, "Updating loan request..."));

    request.then(({
      data
    }) => {
      
      dispatch({ type: 'LOANS_UPDATED', payload: objFormValues })
      dispatch(showNotification({ type: "success", message: "Loan application has successfully been updated!" }));
      dispatch(fetchALoans(loansId))
      dispatch(closeUpdateStatusDialog());
      dispatch(fetchLoanCounts());
      
    },
      (error) => {
        dispatch({ type: 'ERROR_LOANS', errorMsg: error })
        dispatch(closeUpdateStatusDialog());
      });
  }
}

// amortization

export function fetchAmortizations(loansId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS;
  apiBaseUrl += loansId.concat('/amortization');

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return dispatch => {

      dispatch(amortizationsLoading(true))

      request.then(
          ({ data }) => {

              let res = dataFormatter.deserialize(data);
              dispatch({ type: "FETCH_LOAN_AMORTIZATIONS", amortizations: res });

          },
          error => {
              console.log("Error Request", error);

              dispatch(showNotification({ type: "error", message: error.messages }));
          }
      );
  };
}

export function amortizationsLoading(boolIsLoading = true, amortizationsMsg = "Fetching loan amortizations..") {
  return (dispatch) => {
    dispatch({
      type: 'AMORTIZATIONS_LOADING',
      boolIsLoading,
      amortizationsMsg
    })
  }
}

// calculator

export function fetchSampleAmortizations(sampleValues) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_LOANS.concat('sample-amortization');

  let params = { ...sampleValues };

  const request = http.get(apiBaseUrl, { params });

  return dispatch => {

      dispatch(setLoansLoading(true, "Loading.."))

      request.then(
          ({ data }) => {

              console.log(data)
              dispatch({ type: "POPULATE_SAMPLE_AMORTIZATION", amortizations: data });
          },
          error => {
              dispatch(showNotification({ type: "error", message: error.messages }));
          }
      );
  };
}