import Config from '../../../config';

const initState = {
    open: false,
    openDialog: false,
    dialogLoading: false,
    dialogLoadingText: "Please wait...",
    amortizationsLoading: false,
    amortizationsMsg: "",
    amortizations: [],
    updateStatusTo: "",
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    counts: [],
    histories: [],
    submittedLoan: {},
    selectedLoans: {},
    loansUpdated: false,
    loansApplied: false,
    loansCreated: false,
    loansAction: null,
    loansId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function loans(state = initState, action) {

    switch (action.type) {

        case 'FETCH_LOAN_HISTORIES':
            return {
                ...state,
                histories: action.histories,
            };

        case 'FETCH_LOAN_COUNTS':
            return {
                ...state,
                counts: action.counts,
            };

        case 'FETCH_LOANS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_LOANS":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedLoans: {
                    ...action.payload
                },
                loansId: action.loansId,
            };
            
        case 'LOAN_FOR_APPROVAL_SUCCESS':
            return {
                ...state,
                loansApplied: true,
                loansCreated: true,
                loading: false,
                loading_image: false,
                submittedLoan: {
                    ...action.payload
                },
                selectedLoans: {
                    ...action.payload
                }
            }

        case "LOANS_UPDATED":
            return {
                ...state,
                error: false,
                loansUpdated: true,
                loading: false,
                loading_image: false,
                submittedLoan: {
                    ...action.payload
                }
            };

        case 'ERROR_LOANS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false,
                selectedLoans: {
                    ...action.payload
                }
            };

        case "LOANS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }
        
        // confirmation dialog box
        case "SHOW_UPDATE_STATUS_DIALOG":
            return {
                ...state,
                openDialog: true,
                updateStatusTo: action.updateStatusTo
            };

        case "UPDATE_LOAN_STATE":
            return {
                ...state,
                updateStatusTo: action.updateStatusTo
            };
  
        case "HIDE_UPDATE_STATUS_DIALOG":
            return {
                ...state,
                openDialog: false,
                dialogLoading: false,
                updateStatusTo: ""
            };
  
        case "SHOW_UPDATE_STATUS_DIALOG_LOADER":
            return {
            ...state,
            dialogLoading: true,
            dialogLoadingText: "Please wait, while we process your request..."
        };

        case "AMORTIZATIONS_LOADING":
            return {
                ...state,
                error: false,
                amortizationsLoading: action.boolIsLoading,
                amortizationsMsg: action.amortizationsMsg,
            }

        case "FETCH_LOAN_AMORTIZATIONS":
        case "POPULATE_SAMPLE_AMORTIZATION":
            return {
                ...state,
                amortizationsLoading: false,
                amortizations: action.amortizations,
            };
        
        case "LOANS_LIST_DEFAULT":
        case "CLEAR_LOANS_DATA":
        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state
    }

}