import Config from '../../../config';

const initState = {
    open: false,
    showAdditionalFields: true,
    optionalFields: [
        { label: "Disbursement Notes", name: "comment", type: "textarea" },
    ],
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function disbursements(state = initState, action) {

    switch (action.type) {

        case 'TOGGLE_DISBURSEMENTS_MODAL':
            return {
                ...state,
                open: action.toggle
            }


        case 'TOGGLE_DISBURSEMENTS_ADDITIONAL_MODAL_FIELDS':
            return {
                ...state,
                showAdditionalFields: action.toggle
            }



        case "DISBURSEMENTS_LIST_DEFAULT":
        case "CLEAR_DISBURSEMENTS_DATA":
        case "DISMISS_ERROR":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}