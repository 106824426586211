import { combineReducers } from "redux";
import theme_reducer from './theme_reducer'
import auth from './authentication/auth';

import clients from './smfi/clients';
import branches from './settings/branches';
import roles from './settings/roles'
import users from './settings/users'
import fundings from './settings/funding';
import notification from './smfi/notification';
import payment_methods from './settings/payment_methods';
import countries from './countries'
import penalty_types from './settings/penalty_types'
import interest_types from './settings/interest_types'
import witness_types from './settings/witness_types'
import frequency_types from './settings/frequency_types';
import currency from './settings/currency';
import document_types from './settings/document_types';

import loan_types from './products/loan_types';
import savings_types from './products/savings_types';
import interest_calculation from './products/interest_calculation';

import savings from './products/savings';
import loans from './products/loans';

import redux_actions from './smfi/redux_actions'

import dashboard_widgets from './dashboard/widgets'
import dashboard_statistics from './dashboard/statistics'
import branch_statistics from './dashboard/branch_stats';

import payments from './products/payments'
import disbursements from './products/disbursements'

import site_identity from './settings/site_identity'

import reports from './smfi/reports'
import permissions from './settings/permissions'

export default combineReducers({
  theme_reducer,
  site_identity,
  auth,
  clients,
  branches,
  roles,
  users,
  fundings,
  notification,
  payment_methods,
  redux_actions,
  penalty_types,
  interest_types,
  witness_types,
  frequency_types,
  currency,
  document_types,
  countries,
  loan_types,
  savings_types,
  interest_calculation,
  savings,
  loans,
  dashboard_statistics,
  dashboard_widgets,
  branch_statistics,
  payments,
  disbursements,
  reports,
  permissions
});