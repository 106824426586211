import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedPermissions: {
        name: "",
        permissions: ""
    },
    permissionsCreated: false,
    permissionsUpdated: false,
    permissionsAction: null,
    permissionsId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function permissions(state = initState, action) {

    switch (action.type) {
        case "FETCH_A_PERMISSIONS":
            return {
                ...state,
                error: false,
                loading: false,
                data: action.payload,
                permissionsId: action.permissionsId
            };

        case "PERMISSIONS_CREATED":
            return {
                ...state,
                error: false,
                loading: false,
                permissionsCreated: true,
                selectedPermissions: {
                    ...action.payload
                }
            };

        case "PERMISSIONS_UPDATED":
            return {
                ...state,
                error: false,
                permissionsUpdated: true,
                loading: false,
                selectedPermissions: {
                    ...action.payload
                }
            };

        case 'ERROR_PERMISSIONS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false
            };

        case "PERMISSIONS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg
            }
    
        case "DISMISS_ERROR":
            return initState

        default:
            return state
    }
}