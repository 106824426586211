import Config from '../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_first_level: true,
    loading_image: false,
    meta: Config.defaultMeta,
    countries: [],
    firstLevel: [],
    selectedCountry: process.env.REACT_APP_DEFAULT_COUNTRY,
    firstLevelLabel: process.env.REACT_APP_1ST_LEVEL_LABEL
}

export default function countries(state = initState, action) {
    // console.log("countries", action.type)
    switch (action.type) {

        case 'FETCH_COUNTRIES':
            return {
                ...state,
                error: false,
                countries: action.data,
                loading: false,
                loading_image: false
            };

        case "SET_COUNTRY":
            return {
                ...state,
                selectedCountry: action.selectedCountry
            }

        case 'FETCH_FIRST_LEVEL_COUNTRIES':
            return {
                ...state,
                error: false,
                firstLevel: action.data,
                loading_first_level: false,
                loading_image: false
            };


        case 'ERROR_ADDRESS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "COUNTRIES_LOADING":
            return {
                ...state,
                error: false,
                loading: true,
                loadingText: action.loadingMsg,
                loading_image: false
            }


        case "COUNTRIES_FIRST_LEVEL_LOADING":
            return {
                ...state,
                error: false,
                loading_first_level: true,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "CLEAR_ADDRESS_DATA":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_first_level: false,
                loading_image: false
            }

        default:
            return state

    }

}