import Jsona from "jsona";
import http from "../../api/http";

export function createClientAddress(addresses, isCreatingClient = false) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_ADDRESS_URI;

    const clientAddresses = { ...addresses };

    let params = {
        data:
        {
            type: "Address",
            attributes:
            {
                ...clientAddresses
            }
        }
    }

    const request = http.post(apiBaseUrl, params)

    const dataFormatter = new Jsona();

    return (dispatch) => {
        request.then(({
            data
        }) => {

            let res = dataFormatter.deserialize(data)

            dispatch({ type: 'ADDRESS_CREATED', payload: res })

            if (isCreatingClient) dispatch({ type: 'CLIENT_ADDRESS_CREATED' });

        },
            (error) => {
                dispatch({
                    type: 'ERROR_ADDRESS',
                    errorMsg: error
                })

            });
    }
}



export function updateClientAddress(addresses) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_ADDRESS_URI;

    apiBaseUrl += addresses.addressId

    let clientAddresses = { ...addresses };

    delete clientAddresses.addressId

    let params = {
        data:
        {
            type: "Address",
            attributes:
            {
                ...clientAddresses
            }
        }
    }

    const request = http.put(apiBaseUrl, params)

    const dataFormatter = new Jsona();
    return (dispatch) => {
        request.then(({
            data
        }) => {

            let res = dataFormatter.deserialize(data)

            dispatch({ type: 'ADDRESS_CREATED', payload: res })

            dispatch({ type: 'CLIENT_ADDRESS_CREATED' });
            dispatch({ type: 'CLIENT_UPDATED' })

        },
            (error) => {
                dispatch({
                    type: 'ERROR_ADDRESS',
                    errorMsg: error
                })

            });
    }
}