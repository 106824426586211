const initState = {
    interestCalculationList: []
}

export default function interest_calculation(state = initState, action) {

    switch (action.type) {

        case "FETCH_INTEREST_CALCULATION":
            return {
                interestCalculationList: action.interest
            };

        default:
            return state

    }
}