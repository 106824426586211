import Jsona from "jsona";
import http from "../../api/http";
import Config from "../../../config";
import { reactTableFilters, reactTableSort } from "../utils";
import { showNotification } from "../smfi/notification";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";


export function fetchAllOfficers() {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  let params = { filter: { isLoanOfficer : 1 } }

  const request = http.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {

      dispatch(setUsersLoading(true, "Loading.."))

      request.then(
          ({ data }) => {

              let res = dataFormatter.deserialize(data);
              dispatch({ type: "FETCH_OFFICERS", data: res });
          },
          error => {
              dispatch(showNotification({ type: "error", message: error.messages }));
          }
      );
  };
}

export function fetchUsers(state, instance) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  let params = state
    ? {
      page: {
        size: state.pageSize,
        number: state.page + 1
      },
      sort: reactTableSort(state).pop(),
      filter: reactTableFilters(state)
    }
    : {
      ...Config.defaultMeta
    };

  params.module = "user";

  const request = http.get(apiBaseUrl, {
    params
  });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_USERS",
          data: res,
          meta: data.meta
        });
      },
      error => {
        dispatch(showNotification({ type: "error", message: error.messages }));
      }
    );
  };
}

export function fetchAUser(userId) {
  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;
  apiBaseUrl += userId

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_A_USER", payload: res, userId: userId });

      },
      (error) => {
        dispatch(showNotification({ type: 'error', message: error }));
        dispatch({ type: 'ERROR_USERS', errorMsg: strGeneralError })
      }
    );
  };
}

export function createUser(objFormValues) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

  let params = {
    data:
    {
      type: "User",
      attributes:
      {
        ...objFormValues
      }
    }
  }

  const request = http.post(apiBaseUrl, params)

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(({
      data
    }) => {

      let res = dataFormatter.deserialize(data)

      dispatch(setUsersLoading(true, "Creating client address..."));
      dispatch({ type: 'USER_CREATED', payload: res })
      dispatch(showNotification({ type: "success", message: "User has successfully been created!" }));

    },
      (error) => {
        dispatch({
          type: 'ERROR_USERS',
          errorMsg: error,
          payload: objFormValues
        })

        dispatch(showNotification({ type: "error", message: error.messages }));

      });
  }
}

export function updateUser(objFormValues, userId, changedPassword) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;
  apiBaseUrl += userId

  const request = http.put(apiBaseUrl, {
    data:
    {
      type: "User",
      attributes:
      {
        ...objFormValues
      }
    }
  })

  return (dispatch) => {
    //move here to update the form
    dispatch(setUsersLoading(true, "Creating client address..."));

    request.then(({
      data
    }) => {

      //Simply pass the accepted values back to the form values
      dispatch({ type: 'USER_UPDATED', payload: objFormValues })

      // logout user
      if(changedPassword){
        dispatch({ type: 'LOGOUT' })
      }

      dispatch(showNotification({ type: "success", message: data.message }));

    },
      (error) => {
        dispatch({
          type: 'ERROR_USERS',
          errorMsg: error
        })

        dispatch(showNotification({ type: "error", message: error.messages }));
      });
  }
}


export function setUsersLoading(boolIsLoading = true, loadingMsg = "Loading...") {
  return (dispatch) => {
    dispatch({
      type: 'USERS_LOADING',
      boolIsLoading,
      loadingMsg
    })
  }
}


export function defaultUsers() {
  return dispatch => {
    dispatch({
      type: "USER_LIST_DEFAULT"
    });
  };
}

export function dismissError() {
  return (dispatch) => {
    dispatch({ type: 'DISMISS_ERROR' });
  }
}