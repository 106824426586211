import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedCurrency: {
        name: "",
        description: "",
        updatedAt: ""
    },
    currencyCreated: false,
    currencyAction: null,
    currencyId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function currency(state = initState, action) {

    switch (action.type) {

        case 'FETCH_CURRENCY':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_CURRENCY":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedCurrency: {
                    ...action.payload
                },
                currencyId: action.currencyId,
            };

        case "CURRENCY_CREATED":
            return {
                ...state,
                error: false,
                selectedCurrency: {
                    ...action.payload
                },
                currencyCreated: true,
                loading: false,
                loading_image: false
            };


        case "CURRENCY_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false,
                selectedCurrency: {
                    ...action.payload
                }
            };

        case 'ERROR_CURRENCY':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "CURRENCY_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "CURRENCY_LIST_DEFAULT":
        case "CLEAR_CURRENCY_DATA":
        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}