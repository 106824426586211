import Jsona from "jsona";
import http from "../../api/http";
import Config from "../../../config";
import { reactTableFilters, reactTableSort } from "../utils";
import { showNotification } from "../smfi/notification";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function fetchSiteIdentity(state, instance) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_SITE_IDENTITY_URI;

  let params = state
    ? {
      page: {
        size: state.pageSize,
        number: state.page + 1
      },
      sort: reactTableSort(state).pop(),
      filter: reactTableFilters(state)
    }
    : {
      ...Config.defaultMeta
    };

  params.module = "site_identity";

  const request = http.get(apiBaseUrl, {
    params
  });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_SITE_IDENTITY",
          data: res,
          meta: data.meta
        });
      },
      error => {
        dispatch(showNotification({ type: "error", message: error.messages }));
      }
    );
  };
}

export function fetchAllSiteIdentity() {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_SITE_IDENTITY_URI;

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({
          type: "FETCH_SITE_IDENTITY",
          data: res,
          meta: data.meta
        });
      },
      error => {
        dispatch(showNotification({ type: "error", message: error.messages }));
      }
    );
  };
}

export function fetchASiteIdentity(siteIdentityId) {
  
    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_SITE_IDENTITY_URI;
  apiBaseUrl += siteIdentityId

  const request = http.get(apiBaseUrl);

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_A_SITE_IDENTITY", payload: res, siteIdentityId: res.id });

      },
      (error) => {
        dispatch(showNotification({ type: 'error', message: error }));
        dispatch({ type: 'ERROR_SITE_IDENTITY', errorMsg: strGeneralError })
      }
    );
  };
}

export function createSiteIdentity(objFormValues) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_SITE_IDENTITY_URI;

  let params = {
    data:
    {
      type: "site_identity",
      attributes:
      {
        ...objFormValues
      }
    }
  }

  const request = http.post(apiBaseUrl, params)

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(({
      data
    }) => {

      let res = dataFormatter.deserialize(data)

      dispatch(setSiteIdentityLoading(true, "Creating client address..."));
      dispatch({ type: 'SITE_IDENTITY_CREATED', payload: res })
      dispatch(showNotification({ type: "success", message: "SiteIdentity has successfully been created!" }));

    },
      (error) => {
        dispatch({
          type: 'ERROR_SITE_IDENTITY',
          errorMsg: error
        })

        dispatch(showNotification({ type: "error", message: error.messages }));

      });
  }
}

export function updateSiteIdentity(objFormValues, siteIdentityId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_SITE_IDENTITY_URI;
  apiBaseUrl += siteIdentityId

  const request = http.put(apiBaseUrl, {
    data:
    {
      type: "site_identity",
      attributes:
      {
        ...objFormValues
      }
    }
  })

  return (dispatch) => {
    //move here to update the form
    dispatch(setSiteIdentityLoading(true, "Creating client address..."));

    request.then(({
      data
    }) => {

      //Simply pass the accepted values back to the form values
      dispatch({ type: 'SITE_IDENTITY_UPDATED', payload: objFormValues })
      dispatch(showNotification({ type: "success", message: data.message }));
      dispatch({ type: 'LOGOUT' })
    },
      (error) => {
        dispatch({
          type: 'ERROR_SITE_IDENTITY',
          errorMsg: error
        })

        dispatch(showNotification({ type: "error", message: error }));

      });
  }
}


export function setSiteIdentityLoading(boolIsLoading = true, loadingMsg = "Loading...") {
  return (dispatch) => {
    dispatch({
      type: 'SITE_IDENTITY_LOADING',
      boolIsLoading,
      loadingMsg
    })
  }
}


export function defaultSiteIdentity() {
  return dispatch => {
    dispatch({
      type: "SITE_IDENTITY_LIST_DEFAULT"
    });
  };
}

export function dismissError() {
  return (dispatch) => {
    dispatch({ type: 'DISMISS_ERROR' });
  }
}