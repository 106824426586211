import React from 'react';

import { Route, Switch } from "react-router-dom";

import NotFound from "./Containers/Pages/NotFound";
import AuthenticatedRoute from "./App/components/Auth/AuthenticatedRoute";
import UnauthenticatedRoute from "./App/components/Auth/UnauthenticatedRoute";

import routes from "./routes";

import Signin1 from "./Containers/Authentication/SignIn/SignIn1";
import SignUp1 from "./Containers/Authentication/SignUp/SignUp1";
import ResetPassword1 from "./Containers/Authentication/ResetPassword/ResetPassword1";

import { checkPermissions, getPermissionsFromSession } from './store/actions/helpers/permissionHelper'

export default ({ childProps }) => {
  return (
    <Switch>
      {routes.map((route, idx) => {

        let props = { ...childProps }

        let { routeId, requestFilter } = route

        //simply add the route id in the props for referrencing e.g. permissions
        if (routeId) {
          props = { ...props, routeId }
        }

        //router request filter for loans and savings
        if (requestFilter) {
          props = { ...props, requestFilter }
        }

        // console.log('route', route.id, route.permissionId, route)

        //this checks route permissions
        let { permissionId } = route

        if (permissionId) {
          let boolPermision = checkPermissions(permissionId, getPermissionsFromSession())
          if (!boolPermision) {
            return <Route path={route.path} component={NotFound} />
          }

        }

        return (
          <AuthenticatedRoute
            path={route.path}
            exact
            component={route.component}
            props={props}
            key={idx}
          />
        );
      })}

      <UnauthenticatedRoute path="/" exact component={Signin1} props={childProps} />
      <UnauthenticatedRoute path="/auth/signin" exact component={Signin1} props={childProps} />
      <UnauthenticatedRoute path="/auth/signup" exact component={SignUp1} props={childProps} />
      <UnauthenticatedRoute path="/auth/reset-password" exact component={ResetPassword1} props={childProps} />

      <Route component={NotFound} />

    </Switch>
  )
};
