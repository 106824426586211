import React from 'react';
import {NavLink} from 'react-router-dom';

import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
import DEMO from "../../../store/constant";

import logoDark from '../../../assets/images/EmergeBank.png';

class SignUp1 extends React.Component {
    render () {
        return(
            <Aux>
                <div className="page-ath-wrap">
                    <div className="page-ath-content">
                        <div className="page-ath-header">
                            <a href="/" className="page-ath-logo"><img src={logoDark} alt="logo" /></a>
                        </div>
                        <div className="page-ath-form pt-0">
                            <h2 className="page-ath-heading">Sign Up <small>to have your Emergebanking wallet account</small></h2>
                            <form>
                                <div className="input-group mb-3">
                                    <input type="text" className="input-bordered" placeholder="Username"/>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="email" className="input-bordered" placeholder="Email address"/>
                                </div>
                                <div className="input-group mb-4">
                                    <input type="password" className="input-bordered" placeholder="Password"/>
                                </div>
                                <div className="form-group text-left mt-2">
                                    <div className="d-inline">
                                        <input type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2"/>
                                        <label htmlFor="checkbox-fill-2" className="cr m-l-5" checked>Send me the <a href={DEMO.BLANK_LINK}> Newsletter</a> weekly.</label>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-block mb-4 rounded-pill">Sign up</button>
                            </form>
                   
                            <div className="gaps-2x"></div>
                            <div className="gaps-2x"></div>
                            <div className="form-note">Already have an account? <NavLink to="/auth/signin" className="f-w-400"><strong>Sign in here</strong></NavLink> </div>
                        </div>

                        <div className="page-ath-footer">
                            <ul className="footer-links">
                                <li><a href="regular-page.html">Privacy Policy</a></li>
                                <li><a href="regular-page.html">Terms</a></li>
                                <li>&copy; 2020 Emergebanking.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="page-ath-gfx">
                        <div className="w-100 d-flex justify-content-center">
                            {/* <div className="col-md-8 col-xl-5"><img src={athGfx} alt="image" /></div> */}
                        </div>
                    </div>
                 </div>
            </Aux>
        );
    }
}

export default SignUp1;