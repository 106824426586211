import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedSiteIdentity: {
        name: "",
        description: "",
        updatedAt: ""
    },
    siteIdentityCreated: false,
    siteIdentityAction: null,
    siteIdentityId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function site_identity(state = initState, action) {

    switch (action.type) {

        case 'FETCH_SITE_IDENTITY':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_SITE_IDENTITY":
            return {
                ...state,
                error: false,
                selectedSiteIdentity: {
                    ...action.payload
                },
                siteIdentityId: action.siteIdentityId,
                loading: false,
                loading_image: false
            };

        case "SITE_IDENTITY_CREATED":
            return {
                ...state,
                error: false,
                selectedSiteIdentity: {
                    ...action.payload
                },
                siteIdentityCreated: true,
                loading: false,
                loading_image: false
            };


        case "SITE_IDENTITY_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false,
                //reupdate the selected siteIdentity here...
                selectedSiteIdentity: {
                    ...action.payload
                }
            };

        case 'ERROR_SITE_IDENTITY':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "SITE_IDENTITY_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "SITE_IDENTITY_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_SITE_IDENTITY_DATA":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}