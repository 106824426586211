export const COLLAPSE_MENU       = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE     = 'COLLAPSE_TOGGLE';
export const CHANGE_LAYOUT       = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT   = 'CHANGE_SUB_LAYOUT';
export const LAYOUT_TYPE         = 'LAYOUT_TYPE';
export const RESET               = 'RESET';
export const NAV_BACK_COLOR      = 'NAV_BACK_COLOR';
export const NAV_BRAND_COLOR     = 'NAV_BRAND_COLOR';
export const HEADER_BACK_COLOR   = 'HEADER_BACK_COLOR';
export const RTL_LAYOUT          = 'RTL_LAYOUT';
export const NAV_FIXED_LAYOUT    = 'NAV_FIXED_LAYOUT';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const FULL_WIDTH_LAYOUT   = 'FULL_WIDTH_LAYOUT';
export const NAV_CONTENT_LEAVE   = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE  = 'NAV_COLLAPSE_LEAVE';

// settings/branch
export const CREATE_BRANCH = 'CREATE_BRANCH';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';

// settings/branch
export const CREATE_SYSTEM_USER = 'CREATE_SYSTEM_USER';
export const UPDATE_SYSTEM_USER = 'UPDATE_SYSTEM_USER';

// settings/roles
export const CREATE_ROLE = 'CREATE_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

// settings/Capital
export const ADD_FUNDING    = 'ADD_FUNDING';
export const UPDATE_FUNDING = 'UPDATE_FUNDING';

// settings/Payments
export const ADD_PAYMENTS_METHOD    = 'ADD_PAYMENTS_METHOD';
export const UPDATE_PAYMENTS_METHOD = 'UPDATE_PAYMENTS_METHOD';

// settings/penalty-types
export const ADD_PENALTY_TYPE    = 'ADD_PENALTY_TYPE';
export const UPDATE_PENALTY_TYPE = 'UPDATE_PENALTY_TYPE';

// settings/interest-types
export const ADD_INTEREST_TYPE    = 'ADD_PROFIT_TYPE';
export const UPDATE_INTEREST_TYPE = 'UPDATE_PROFIT_TYPE';

// settings/interest-types
export const ADD_WITNESS_TYPE    = 'ADD_WITNESS_TYPE';
export const UPDATE_WITNESS_TYPE = 'UPDATE_WITNESS_TYPE';

// settings/frequency-types
export const ADD_FREQUENCY_TYPE    = 'ADD_FREQUENCY_TYPE';
export const UPDATE_FREQUENCY_TYPE = 'UPDATE_FREQUENCY_TYPE';

// settings/document-types
export const ADD_DOCUMENT_TYPE    = 'ADD_DOCUMENT_TYPE';
export const UPDATE_DOCUMENT_TYPE = 'UPDATE_DOCUMENT_TYPE';

// settings/currency
export const ADD_CURRENCY    = 'ADD_CURRENCY';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';

// products/savings
export const ADD_SAVINGS_TYPES    = 'ADD_SAVINGS_TYPES';
export const UPDATE_SAVINGS_TYPES = 'UPDATE_SAVINGS_TYPES';
export const SAVINGS_CREATED      = 'Savings type has successfully been created!'

// products/loans
export const ADD_LOAN_TYPES    = 'ADD_LOAN_TYPES';
export const UPDATE_LOAN_TYPES = 'UPDATE_LOAN_TYPES';
export const LOAN_CREATED      = 'Loan type has successfully been created!'

//
export const FOR_APPROVAL = 'for-approval';
export const FOR_UPDATE   = 'for-update';

export const PENDING_FOR_OPENING_BALANCE = 'pending-opening-balance';
export const PENDING_FOR_DISBURSEMENT    = 'pending-disbursement'
export const RECEIVE_OPENING_BALANCE     = 'receive-opening-balance'
export const ACTIVE                      = 'active'
export const REJECTED                    = 'rejected'
export const CANCELLED                   = 'cancelled'
export const SETTLED                     = 'settled'
export const CLOSED                      = 'closed'

// loan proccess
export const NEW_LOAN_APPLICATION     = 'new-loan';
export const LOAN_APPLICATION_SUCCESS = 'Loan application has successfully been submitted'
export const UPDATE_LOAN              = 'UPDATE_LOAN'
export const CREATE_LOAN              = 'SUBMIT_APPLICATION'

// Savings Process
export const NEW_SAVINGS_APPLICATION     = 'new-savings';
export const SAVINGS_APPLICATION_SUCCESS = 'Saving application has successfully been submitted'
export const UPDATE_SAVINGS              = 'UPDATE_SAVINGS'
export const CREATE_SAVINGS              = 'SUBMIT_APPLICATION'
export const DEPOSIT_SAVINGS             = 'deposit'
export const WITHDRAW_SAVINGS            = 'withdraw'

// settings/general
export const CREATE_SITE_IDENTITY = 'CREATE_SITE_SETTING';
export const UPDATE_SITE_IDENTITY = 'UPDATE_SETTING';