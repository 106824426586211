import { flattenPermissions } from '../../actions/helpers/permissionHelper'

//need for first render
const initState = {
    accessToken: false,
    error: false,
    reset_success: false,
    user: {
        firstname: null,
        lastname: null,
        email: null,
        createdAt: null,
        permissions: null,
        flatPermissions: null,
        branch: null,
        role: null,
        isAdmin: null,
        isExpired: false,
        temporaryPassword: null
    },
    loading: false,
    loading_image: false
};

export default function auth(state = initState, action) {

    //let defaultUser = initState.user;

    switch (action.type) {

        case "LOGIN":
            let loginRefPermissions = flattenPermissions(action.user.permissions)
            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user: {
                    id: action.user.id,
                    Firstname: action.user.firstName,
                    Middlename: action.user.middleName || "",
                    Lastname: action.user.lastName,
                    email: action.user.email,
                    createdAt: action.user.createdAt,
                    permissions: JSON.parse(action.user.permissions),
                    flatPermissions: loginRefPermissions,
                    branch: action.user.branch,
                    role: action.user.role,
                    isAdmin: action.user.isAdmin,
                    isExpired: action.user.isExpired,
                    temporaryPassword:  action.user.temporaryPassword
                },

            };

        case "AUTH_LOAD_SESSION": {

            let sessionRefPermissions = flattenPermissions(action.user.permissions)
            return {
                ...state,
                error: false,
                ...action.auth,
                loading: false,
                user: {
                    id: action.user.id,
                    Firstname: action.user.firstName,
                    Middlename: action.user.middleName || "",
                    Lastname: action.user.lastName,
                    email: action.user.email,
                    createdAt: action.user.createdAt,
                    permissions: JSON.parse(action.user.permissions),
                    flatPermissions: sessionRefPermissions,
                    branch: action.user.branch,
                    role: action.user.role,
                    isAdmin: action.user.isAdmin,
                    isExpired: action.user.isExpired,
                    temporaryPassword:  action.user.temporaryPassword
                },
            }
        }

        case "AUTH_LOADING":

            return {
                ...state,
                error: false,
                loading: true
            };

        case "AUTH_FAILED":
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg,
                loading: false
            };

        case "DISMISS_AUTH_ERROR":
            return initState;

        case "LOGOUT":

            let username = localStorage.getItem('username');
            let password = localStorage.getItem('password');
            let rememberme = localStorage.getItem('rememberme');

            localStorage.clear();

            if (rememberme) {
                localStorage.setItem('username', username);
                localStorage.setItem('password', password);
                localStorage.setItem('rememberme', rememberme);
            }

            window.location.href = "/auth/signin";

            return initState;

        default:
            return state;
    }

}