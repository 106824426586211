import moment from 'moment'

import formatCurrency from '../../actions/helpers/numeralFormat'

let now = moment()

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Statistics...",
    totalLoans: formatCurrency(0),
    totalSavings: formatCurrency(0),
    totalWithdrawals: formatCurrency(0),
    selectedYear: now.format('YYYY'),
    yearSelection: [now.format('YYYY'), now.subtract(1, 'years').format('YYYY'), now.subtract(1, 'years').format('YYYY')],
    data: {
        height: 350,
        type: 'bar',
        options: {
            // plotOptions: {
            //     bar: {
            //         horizontal: false,
            //         columnWidth: '40%'
            //     },
            // },
            chart: {
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            legend: {
                fontSize: '14px',
                position: 'top',
                horizontalAlign: 'left',
                markers: {
                    width: 14,
                    height: 14,
                    radius: 2
                },
                itemMargin: {
                    horizontal: 0,
                    vertical: 8
                }
            },
            markers: {
                size: 1.5,
                strokeColors: ['#ffb74e', '#4782fa', '#fe365f'],
                hover: {
                    size: 3
                }
            },
            colors: ['#ffb74e', '#4782fa', '#fe365f'],
            grid: {
                borderColor: '#ccc',
                strokeDashArray: 3
            },
            xaxis: {
                categories: moment.monthsShort(),
            } //jan - dec
        },
        series: [
            {
                name: 'Total Loans',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
            },
            {
                name: 'Total Savings',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
            },
            {
                name: 'Total Withdrawal',
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]
            }
        ]
    },
}

export default function statistics(state = initState, action) {

    switch (action.type) {

        case 'FETCH_STATISTICS':

            // console.log('FETCH_STATISTICS action.data', action.data)

            let {
                monthlyLoans,
                monthlySavings,
                monthlyWithdrawals,
                totalLoans,
                totalSavings,
                totalWithdrawals,
                year
            } = action.data

            let series = [
                {
                    name: "Monthly Loans",
                    data: monthlyLoans
                },
                {
                    name: "Monthly Savings",
                    data: monthlySavings
                },
                {
                    name: "Monthly Withdrawals",
                    data: monthlyWithdrawals
                }
            ]

            return {
                ...state,
                error: false,
                data: {
                    ...state.data,
                    series
                },
                totalLoans: formatCurrency(totalLoans),
                totalSavings: formatCurrency(totalSavings),
                totalWithdrawals: formatCurrency(totalWithdrawals),
                loading: false,
                loading_image: false,
                selectedYear: year
            };


        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}