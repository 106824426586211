const tempConfig = {
    ...process.env
};

let config = {
    ...tempConfig,
    defaultMeta: {
        //used for table defaults
        itemTotal: process.env.REACT_APP_DEFAULT_PAGINATE_ITEM_TOTAL,
        pageNumber: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_NUMBER,
        pageSize: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_SIZE,
        pageTotal: process.env.REACT_APP_DEFAULT_PAGINATE_PAGE_TOTAL,
        sort: process.env.REACT_APP_DEFAULT_PAGINATE_SORT_FIED
    },
    defaultPath: '/smfi',
    basename: '', // only at build time to set, like ///mintone/react/default
    layout: 'vertical', // vertical, horizontal
    collapseMenu: false, // mini-menu
    layoutType: 'menu-light', // menu-dark, menu-light
    headerBackColor: '', // header-blue, header-red, header-purple, header-info, header-dark
    fullWidthLayout: true,
    navFixedLayout: true,
    headerFixedLayout: true
}

export default config

