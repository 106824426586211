import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    officers: [],
    selectedUser: {
        name: "",
        description: "",
        updatedAt: ""
    },
    userCreated: false,
    userUpdated: false,
    logoutRequired: false,
    userAction: null,
    userId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],
    dropdownOpen: new Array(10).fill(false),
    defaultPermissions: null,
    permissionsLoading: false
}

export default function users(state = initState, action) {
    // console.log(action.type, action, state)
    switch (action.type) {

        
        case "USER_PERMISSSIONS_LOADING":
            return {
                ...state,
                permissionsLoading: action.boolIsLoading,
            }

        case 'CLEAR_INITIAL_PERMISIONS':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    permissions: action.initialPermissions
                },
                permissionsLoading: true
            }
            
        case 'INITIAL_PERMISIONS':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    permissions: action.initialPermissions
                },
                permissionsLoading: false
            }

        case 'FETCH_OFFICERS':
            return {
                ...state,
                error: false,
                officers: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case 'FETCH_USERS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_USER":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                defaultPermissions: action.payload.permissions,
                userId: action.userId,
                loading: false,
                loading_image: false
            };

        case "USER_CREATED":
            return {
                ...state,
                error: false,
                selectedUser: {
                    ...action.payload
                },
                userCreated: true,
                loading: false,
                loading_image: false
            };


        case "USER_UPDATED":
            return {
                ...state,
                error: false,
                userUpdated: true,
                loading: false,
                loading_image: false,
                //reupdate the selected user here...
                selectedUser: {
                    ...action.payload
                },
                logoutRequired: true
            };
        
        case "CANCEL_LOGOUT":
            return {
                ...state,
                logoutRequired: false
            };

        case 'ERROR_USERS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false,
                selectedUser: {
                    ...action.payload
                }
            };

        case "USERS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "USER_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_USER_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}