import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import config from '../../../config';
import navigation from '../../../menu-items';
import DEMO from "../../../store/constant";
import Aux from "../../../hoc/_Aux/index";
import FlexBreadcrumb from './FlexBreadcrumb'

class Breadcrumb extends Component {
    state = {
        main: [],
        item: []
    };

    componentDidMount() {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item, index);
            }
            return false;
        });
    };

    UNSAFE_componentWillReceiveProps = () => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                this.getCollapse(item);
            }
            return false;
        });
    };

    getCollapse = (item) => {
        if (item.children) {
            (item.children).filter(collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    this.getCollapse(collapse);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === config.basename + collapse.url) {
                        this.setState({ item: collapse, main: item });
                    }
                }
                return false;
            });
        }
    };

    render() {

        // this is the general crumbs
        let main, item;
        let breadcrumb = '';
        let title = 'Welcome';
        let titleSuffix = ' | Microfinance Application';
        if (this.state.main && this.state.main.type === 'collapse') {
            main = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{this.state.main.title}</a>
                </li>
            );
        }

        if (this.state.item && this.state.item.type === 'item') {
            title = this.state.item.title;
            item = (
                <li className="breadcrumb-item">
                    <a href={DEMO.BLANK_LINK}>{title}</a>
                </li>
            );

            if (this.state.item.breadcrumbs !== false) {
                breadcrumb = (
                    <div className="page-block">
                        <div className="page-header-title">
                            <h5 className="m-b-10">{title}</h5>
                        </div>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/"><i className="feather icon-home" /></Link>
                            </li>
                            {main}
                            {item}
                        </ul>
                    </div>
                );
            }

        }

        document.title = title + titleSuffix;


        //custom overrides for force menu such as details page and others
        /* to use: <Breadcrumbs
         overrideMenu={true}
         pageTitle="Role Details"
         documentTitle="Roles"
         crumbData={[
             {
                 path: "/settings/role",
                 title: "User & Roles",
             },
             {
                 path: "/settings/role",
                 title: "Roles",
             }
         ]}
         
        /> */

        let {
            overrideMenu
        } = this.props

        //this simply replaces the data
        if (overrideMenu) {
            let {
                pageTitle,
                documentTitle,
                crumbData
            } = this.props

            breadcrumb = <FlexBreadcrumb
                overrideMenu={overrideMenu}
                pageTitle={pageTitle}
                documentTitle={documentTitle}
                crumbData={crumbData}
            />
        }

        return (
            <Aux>
                {breadcrumb}
            </Aux>
        );
    }
}

export default Breadcrumb;