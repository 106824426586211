const initState = {
  loading: false,
  requiredDocs: [],
  loadingMsg: "Loading..",
  openDialog: false,
  dialogLoading: false,
  dialogLoadingText: "",
  loansId: null,
  dropdownOpen : new Array(10).fill(false),
  moduleActioin: null,
  moduleId     : null,
  user_action  : "ADD"
};

export default function redux_actions(state = initState, action) {

  switch (action.type) {

    case "FETCH_REQUIRED_DOCUMENTS":
    case "UPDATE_REQUIRED_DOCS_LIST":
      return {
          ...state,
          loading: false,
          requiredDocs: action.data
      };
      
    case 'SET_DROPDOWN_ACTION_LENGTH':
      return {
          ...state,
          dropdownOpen: new Array(action.menuLength).fill(false)
      };

    case 'UPDATE_REDUX_ACTIONS_MENUS':
      return {
          ...state,
          dropdownOpen: action.toggle
      };

    case 'USER_ACTION':
      return {
          ...state,
          loading: false,
          user_action: action.user_action
      };

      // confirmation dialog box
    case "SHOW_AMORTIZATIONS_DIALOG":
      return {
          ...state,
          openDialog: true,
          loansId: action.loansId
      };
  
    case "HIDE_AMORTIZATIONS_DIALOG":
        return {
            ...state,
            loansId: null,
            openDialog: false,
            dialogLoading: false
        };
  
    case "SHOW_AMORTIZATIONS_DIALOG_LOADER":
          return {
          ...state,
          loansId: null,
          dialogLoading: true,
          dialogLoadingText: "Please wait, while we process your request..."
      };
      
    default: 
      return state;

  }
}
