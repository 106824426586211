import moment from 'moment'
import _ from 'lodash';

// import formatCurrency from '../../actions/helpers/numeralFormat'

let now = moment()


const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Statistics...",
    branchSelectedYear: now.format('YYYY'),
    branchYearSelection: [now.format('YYYY'), now.subtract(1, 'years').format('YYYY'), now.subtract(1, 'years').format('YYYY')],
    data: {
        height: 350,
        type: 'line',
        options: {
            chart: {
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 1,
            },
            legend: {
                fontSize: '14px',
                position: 'top',
                horizontalAlign: 'left',
                markers: {
                    width: 14,
                    height: 14,
                    radius: 2
                },
                itemMargin: {
                    horizontal: 0,
                    vertical: 8
                }
            },
            markers: {
                size: 1.5,
                strokeColors: ['#7ad835', '#4886ff', '#ffb74e'],
                hover: {
                    size: 3
                }
            },
            // colors: ['#7ad835', '#4886ff', '#ffb74e'],
            grid: {
                borderColor: '#eee'
            },
            xaxis: {
                categories: moment.monthsShort()
            }
        },
        series: []
    },
    branchFunds: {
        height: 320,
        type: 'donut',
        options: {
            labels: [],
            dataLabels: {
                enabled: true
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '40%'
                    }
                }
            },
            legend: {
                position: 'bottom'
            },
            grid: {
                padding: {
                    top: 20,
                    right: 0,
                    bottom: 0,
                    left: 0
                },
            },
        },
        series: []
    }
}

export default function branch_statistics(state = initState, action) {

    switch (action.type) {

        case 'FETCH_BRANCH_STATISTICS':

            let {
                stats,
                year
            } = action.data

            let series = []
            _.map(stats, (values, indx) => {
                series.push({
                    name: indx.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(),
                    data: values
                })
            })
            
            return {
                ...state,
                error: false,
                data: {
                    ...state.data,
                    series
                },
                loading: false,
                loading_image: false,
                branchSelectedYear: year
            };

        case "FETCH_BRANCH_TOTAL_FUNDINGS":

            return {
                ...state,
                error: false,
                branchFunds: {
                    ...state.branchFunds,
                    options: {
                        ...state.branchFunds.options,
                        labels: action.branchFunds.branchFunds.branch
                    },
                    series: action.branchFunds.branchFunds.funds
                },
                loading: false,
            }

        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}