import _ from 'lodash'

import { eachDeep } from 'deepdash-es/standalone';

//How to use checkPermissions
// item - (object || string) - id of the permission or object of the menu id
// permissions - should be pulled from state.auth.user.flatPermissions redux data 


export function checkPermissions(item, permissions) {
    let { id } = item

    if (typeof item === 'string') {
        id = item
    }

    let permission = _.find(permissions, { id })

    if (!permission) return true

    let { state } = permission


    switch (state) {
        case 1:
        case 3:
            return true

        case 2:
            return false

        default:
            return true
    }
}

export function checkGroupPermissions(item, permissions) {
    let { id } = item
    let permission = _.find(permissions, { id, "menu-level": "collapse" })

    if (!permission) return false

    let { state } = permission

    switch (state) {
        case 1:
        case 3:
            return true

        case 2:
            return false

        default:
            return true
    }
}


export function flattenPermissions(strPermissions) {
    let permissions = JSON.parse(strPermissions)

    let flatPermissions = []

    eachDeep(permissions, (value, key, parent, context) => {
        //to flatten just find all unique keys
        if (key === 'id') {
            flatPermissions.push(
                parent
            );
        }
    });
    return flatPermissions
}

export function getPermissionsFromSession() {
    let loginRefPermissions = []
    if (localStorage.getItem('smfiuserinfo')) {

        let objUserData = JSON.parse(localStorage.getItem('smfiuserinfo'))
        loginRefPermissions = flattenPermissions(objUserData.permissions)
        // console.log('found session permission', loginRefPermissions)
    }
    // console.log('final permissions', loginRefPermissions)

    return loginRefPermissions
}