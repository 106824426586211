import Config from '../../../config';

const initState = {
    open: false,
    showAdditionalFields: false,
    transactionType: null,
    optionalFields: [
        { name: "accountNo", type: "text" },
        { name: "chequeNo", type: "text" },
        { name: "routingCode", type: "text" },
        { name: "receiptNo", type: "text" },
        { name: "bank", type: "text" },
        { name: "note", type: "textarea" },
    ],
    openDialog: false,
    dialogLoading: false,
    dialogLoadingText: "Please wait...",
    updateStatusTo: "",
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    counts: [],
    histories: [],
    transactions: [],
    submittedSavings: {},
    selectedSavings: {
        name: "",
        description: "",
        updatedAt: ""
    },
    savingsUpdated: false,
    savingsApplied: false,
    savingsCreated: false,
    savingsAction: null,
    savingsId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function savings(state = initState, action) {

    switch (action.type) {

        case 'FETCH_SAVINGS_TRANSACTIONS':
            return {
                ...state,
                error: false,
                transactions: action.data,
                meta: action.meta,
                loading: false,
            };

        case 'FETCH_SAVINGS_HISTORIES':
            return {
                ...state,
                histories: action.histories,
            };

        case 'FETCH_SAVINGS':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_SAVINGS":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedSavings: {
                    ...action.payload
                },
                savingsId: action.savingsId,
            };

        case 'SAVINGS_FOR_APPROVAL_SUCCESS':
            return {
                ...state,
                savingsApplied: true,
                savingsCreated: true,
                loading: false,
                loading_image: false,
                submittedSavings: {
                    ...action.payload
                },
                selectedSavings: {
                    ...action.payload
                }
            }
            
        case "SAVINGS_UPDATED":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                savingsUpdated: true,
                submittedSavings: {
                    ...action.payload
                }
            };

        case 'ERROR_SAVINGS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "SAVINGS_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        // confirmation dialog box
        case "SHOW_UPDATE_STATUS_DIALOG":
            return {
                ...state,
                openDialog: true,
                updateStatusTo: action.updateStatusTo
            };

        case "HIDE_UPDATE_STATUS_DIALOG":
            return {
                ...state,
                openDialog: false,
                dialogLoading: false,
                updateStatusTo: ""
            };

        case "SHOW_UPDATE_STATUS_DIALOG_LOADER":
            return {
            ...state,
            dialogLoading: true,
            dialogLoadingText: "Please wait, while we process your request..."
        };

        case 'TOGGLE_SAVINGS_TRANSACTION_MODAL':
            return {
                ...state,
                open: action.toggle,
                transactionType: action.transactionType
            }

        case 'TOGGLE_SAVINGS_TRANSACTION_ADDITIONAL_MODAL_FIELDS':
            return {
                ...state,
                showAdditionalFields: action.toggle
            }

        case "SAVINGS_LIST_DEFAULT":
        case "CLEAR_SAVINGS_DATA":
        case "CLEAR_DISBURSEMENTS_DATA":
        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state
    }

}