import moment from 'moment'

var yearStart = parseInt(process.env.REACT_APP_REPORTS_YEAR_START, 10)
var yearEnd = parseInt(moment().format('YYYY'), 10)

let yearsCollection = []
for (let year = yearStart; year <= yearEnd; year++) {
    yearsCollection.push({ label: year, value: year })
}


const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: false,
    loadingText: "Processing Loading...",
    filters: {
        date_type: 'monthly',
        period: moment().format('M'),
        year: moment().format('YYYY'),
        date_from: moment().subtract(7, "d").format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD')
    },
    formFields: [
        {
            name: "date_type",
            type: "select",
            label: "Report Type",
            options: [
                { label: "Monthly", value: "monthly" },
                { label: "Quarterly", value: "quarterly" },
                // { label: "Custom", value: "custom" }
            ]
        },
        {
            name: "period",
            type: "select",
            label: "Month",
            visibleondata: 'monthly',
            value: moment().format('M'),
            options: moment.months().map((month, i) => {
                return { value: i, label: month }
            })
        },
        {
            name: "period",
            type: "select",
            label: "Report Range",
            visibleondata: 'quarterly',
            value: moment().quarter(),
            options: [
                { label: "1st Quarter(Jan-Mar)", value: 1 },
                { label: "2nd Quarter(Apr-Jul)", value: 2 },
                { label: "3rd Quarter(Jul-Sept)", value: 3 },
                { label: "4rth Quarter(Oct-Dec)", value: 4 },
            ]
        },
        {
            name: "year",
            type: "select",
            label: "Year",
            value: parseInt(moment().format('YYYY'), 10),
            options: yearsCollection
        },
        {
            name: "daterange",
            type: "daterange",
            visibleondata: 'custom',
            defaultDateStart: moment().subtract(7, "d").format('MM/DD/YYYY'),
            defaultDateEnd: moment().format('MM/DD/YYYY'),
        },
    ],
    reportList: [],
    loading_list: false,
    displayKeys: ["sortNumber", "name", "action"],
    generationButtonList: []
};

export default function reports(state = initState, action) {
    switch (action.type) {

        case "REPORTS_LOADING":
            return {
                ...state,
                loading: true
            }

        case "REPORTS_LOADING_LIST":
            return {
                ...state,
                loading_list: true
            }

        case "REPORTS_UPDATE_FIELD":
            let newFilterFields = {
                ...state.filters,
                ...action.filters
            }

            return {
                ...state,
                filters: newFilterFields
            }

        case "REPORTS_FETCH_LIST":
            return {
                ...state,
                reportList: action.data,
                loading_list: false
            }

        case "REPORTS_GENERATED_REPORT":
            return {
                ...state,
                loading: false
            }

        case "REPORTS_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_REPORTS_DATA":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_list: false
            }

        default:
            return state;
    }
}
