import Config from '../../../config';

const initState = {
    open: false,
    showAdditionalFields: false,
    optionalFields: [
        { name: "accountNo", type: "text" },
        { name: "chequeNo", type: "text" },
        { name: "routingCode", type: "text" },
        { name: "receiptNo", type: "text" },
        { name: "bank", type: "text" },
        { name: "note", type: "textarea" },
    ],
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedRepayment: {
        name: "",
        description: "",
        updatedAt: ""
    },
    repaymentCreated: false,
    repaymentAction: null,
    repaymentId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function payments(state = initState, action) {

    switch (action.type) {

        case 'FETCH_REPAYMENT':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_REPAYMENT":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedRepayment: {
                    ...action.payload
                },
                repaymentId: action.repaymentId,
            };

        case "REPAYMENT_CREATED":
            return {
                ...state,
                error: false,
                selectedRepayment: {
                    ...action.payload
                },
                repaymentCreated: true,
                loading: false,
                loading_image: false
            };


        case "REPAYMENT_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false,
                selectedRepayment: {
                    ...action.payload
                }
            };

        case 'ERROR_REPAYMENT':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "REPAYMENT_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }


        case 'TOGGLE_PAYMENT_MODAL':
            return {
                ...state,
                open: action.toggle
            }


        case 'TOGGLE_PAYMENT_ADDITIONAL_MODAL_FIELDS':
            return {
                ...state,
                showAdditionalFields: action.toggle
            }



        case "REPAYMENT_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_PAYMENTS_DATA":
            return initState
    

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}