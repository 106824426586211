import React, { Component, Suspense } from 'react';
import { Switch, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import { withCookies } from 'react-cookie';
import { connect } from "react-redux";

import { loadAuthUserFromSession } from '../store/actions/authentication/auth'

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';

// import routes from "../route";
import AuthRouter from "../route";

import { fetchAllCountries } from '../store/actions/countries'
import { fetchASiteIdentity } from '../store/actions/settings/site_identity'
import { fetchLoanCounts } from '../store/actions/products/loans';
import { checkPermissions } from '../store/actions/helpers/permissionHelper'
import { updateUser } from "../store/actions/settings/users";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Row, Col, Button } from 'react-bootstrap';

import { AvForm, AvField, AvGroup } from 'availity-reactstrap-validation';
import { Label, Alert } from 'reactstrap';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }

    userHasAuthenticated = authenticated => {
        this.setState({ isAuthenticated: authenticated });
        this.props.loadInitialList()
        this.props.fetchASiteIdentity(process.env.REACT_APP_CLIENT_ID);
    }

    async componentDidMount() {

        try {

            //find the session on sessionStorage
            if (localStorage.getItem('smfisession') &&
                localStorage.getItem('smfiuserinfo')) {
                //this happens when user reloads
                this.userHasAuthenticated(true);

                //get the data from auth user
                this.props.loadAuthUserFromSession()
            }
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        //set authentication here...
        this.setState({ isAuthenticating: false });
    }

    handleSubmit(event, error, values) {

        if(error.length > 0) return;
        if(values.password !== values.password2){
            alert('Password did not match!');
            return;
        }

        const { auth } = this.props;

        this.props.updateUser({ 
            password: values.password, 
            temporaryPassword: null }, 
            auth.id, 
            true
        );

    }

    componentDidUpdate(prevProps) {
        //set redirection into tenant when it is home and accesstoken is already created
        let { accessToken, user } = this.props.auth

        if (accessToken !== null) {
            if (prevProps.auth.accessToken !== accessToken) {
                this.props.loadInitialList()
                this.props.fetchASiteIdentity(process.env.REACT_APP_CLIENT_ID);
            }
        }

        if(user.temporaryPassword){
            // alert('password need to be change');
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                    <div className='custom-ui'>
                        <h1>Welcome to EmergeBank!</h1>
                        <Alert color={'info'} isOpen={true}>
                            <p>Please reset your password to be able to access your account completely!</p>
                            <strong>Your account will be logout automatically for the changes to take effect</strong>
                        </Alert>
                        <AvForm onSubmit={this.handleSubmit.bind(this)} ref={f => (this.form = f)}>
                            <Row>
                                <Col md={7}>
                                    <AvGroup>
                                        <Label for="password" className="required">New Password</Label>
                                        <AvField name="password" type="password" required/>
                                    </AvGroup>
                                    <AvGroup>
                                        <Label for="password" className="required">Confirm Password</Label>
                                        <AvField name="password2" type="password" required errorMessage="Password did not match"/>
                                    </AvGroup>
                                </Col>
                            </Row>
                        </AvForm>

                        <Button onClick={this.props.logoutAccount} className='btn-icon m-r-5' variant={'outline-secondary'}>Logout</Button>
                        <Button 
                            onClick={() => {

                                this.form.submit();
                                // onClose();
                            }}
                        className='btn-icon' variant={'outline-info'}>Reset Password</Button>
                    </div>
                    );
                },
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }

    }

    handleLogout = event => {
        //clear session storage
        localStorage.clear();
        this.userHasAuthenticated(false);
        //redirect to login page
        this.props.history.push("/signin");
    }


    render() {

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            permissions: this.props.permissions,
            checkPermissions: checkPermissions,
        };

        const headerProps = {
            handleLogout: this.handleLogout
        };

        if (!this.state.isAuthenticating && this.state.isAuthenticated) {

            return (<Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <AdminLayout AppProps={headerProps} childProps={childProps} {...this.props} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>)

        } else {

            return <Aux>
                <AuthRouter childProps={childProps} {...this.props} />
            </Aux>;

        }
    }
}

const mapStateToProps = (state) => {

    let { REACT_APP_STAGE } = process.env

    let appState = (REACT_APP_STAGE === 'development') ? {
        ...state
    } : {}

    appState = {
        ...appState,
        auth: { ...state.auth },
        countries: { ...state.countries },
        site_identity: { ...state.site_identity.selectedSiteIdentity },
        loanCounts: [state.loans.counts],
        permissions: state.auth.user.flatPermissions
    }

    return appState
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadInitialList: () => {
            dispatch(fetchAllCountries())
            // dispatch(fetch1stLevelByCountry())
            dispatch(fetchLoanCounts())
        },
        fetchASiteIdentity: (clientId) => {
            dispatch(fetchASiteIdentity(clientId))
        },
        loadAuthUserFromSession: () => {
            dispatch(loadAuthUserFromSession())
        },
        updateUser: (data, userId, changePassword) => {
            dispatch(updateUser(data, userId, changePassword));
        },
        logoutAccount: () => {
            dispatch({ type: 'LOGOUT' });
        }
    }
}

export default withCookies(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));

