export default {
    items: [
        {
            id: 'navigation',
            title: 'Navigation',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'dashboard-page',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    classes: 'nav-item',
                    icon: 'feather icon-activity'
                },
                {
                    id: 'members-page',
                    title: 'Clients',
                    type: 'item',
                    url: '/clients',
                    classes: 'nav-item',
                    icon: 'feather icon-users'
                },
                {
                    id: 'active-loan-page',
                    title: 'Active Loans',
                    type: 'item',
                    url: '/settings/products/loans/active',
                    classes: 'nav-item',
                    icon: 'feather icon-star',
                    badge: {
                        title: 'active',
                        type: 'badge-warning'
                    }
                },
                {
                    id: 'menu-level-loans',
                    title: 'Loans',
                    type: 'collapse',
                    icon: 'feather icon-bar-chart-2',
                    children: [
                        {
                            id: 'menu-level-for-approval',
                            title: 'For Approval',
                            type: 'item',
                            url: '/settings/products/loans/for-approval',
                            badge: {
                                title: 'forApproval',
                                type: 'badge-secondary'
                            }
                        },
                        {
                            id: 'menu-level-for-update',
                            title: 'For Update',
                            type: 'item',
                            url: '/settings/products/loans/for-update',
                            badge: {
                                title: 'forUpdate',
                                type: 'badge-secondary'
                            }
                        },
                        {
                            id: 'menu-level-pending-disbursement',
                            title: 'For Disbursement',
                            type: 'item',
                            url: '/settings/products/loans/pending-disbursement',
                            badge: {
                                title: 'pendingDisbursement',
                                type: 'badge-secondary'
                            }
                        },
                        {
                            id: 'menu-level-rejected',
                            title: 'Rejected',
                            type: 'item',
                            url: '/settings/products/loans/rejected',
                            badge: {
                                title: 'rejected',
                                type: 'badge-secondary'
                            }
                        },
                        {
                            id: 'menu-level-cancelled',
                            title: 'Cancelled',
                            type: 'item',
                            url: '/settings/products/loans/cancelled',
                            badge: {
                                title: 'cancelled',
                                type: 'badge-secondary'
                            }
                        },
                        {
                            id: 'menu-level-closed',
                            title: 'Settled',
                            type: 'item',
                            url: '/settings/products/loans/settled',
                            badge: {
                                title: 'settled',
                                type: 'badge-secondary'
                            }
                        },
                    ]
                },
                // {
                //     id: 'menu-level-savings',
                //     title: 'Savings',
                //     type: 'item',
                //     url: '/settings/products/savings/for-approval',
                //     icon: 'feather icon-box',
                //     classes: 'nav-item'
                // },
                {
                    id: 'menu-level-savings',
                    title: 'Savings',
                    type: 'collapse',
                    icon: 'feather icon-align-left',
                    children: [
                        {
                            id: 'menu-level-savings-accounts',
                            title: 'Accounts',
                            type: 'item',
                            url: '/settings/products/savings/for-approval',
                        },
                        {
                            id: 'menu-level-savings-transaction',
                            title: 'Transactions',
                            type: 'item',
                            url: '/products/savings/list/transactions',
                        }
                    ]
                },
                // {
                //     id: 'application-page',
                //     title: 'Applications',
                //     type: 'collapse',
                //     icon: 'feather icon-align-left',
                //     children: [
                //         {
                //             id: 'menu-level-loans',
                //             title: 'Loans',
                //             type: 'item',
                //             url: '/settings/products/loans/for-approval',
                //         },
                //         {
                //             id: 'menu-level-savings',
                //             title: 'Savings',
                //             type: 'item',
                //             url: '/settings/products/savings/for-approval',
                //         }
                //     ]
                // },
                // {
                //     id: 'deposit-page',
                //     title: 'Deposit',
                //     type: 'item',
                //     url: '/accounts/deposit',
                //     classes: 'nav-item',
                //     icon: 'feather icon-box'
                // },
                // {
                //     id: 'withdrawal-page',
                //     title: 'Withdrawal',
                //     type: 'item',
                //     url: '/accounts/withdrawal',
                //     classes: 'nav-item',
                //     icon: 'feather icon-briefcase'
                // },
                {
                    id: 'product-level',
                    title: 'Products',
                    type: 'collapse',
                    icon: 'feather icon-package',
                    children: [
                        {
                            id: 'menu-level-loan',
                            title: 'Loans',
                            type: 'item',
                            url: '/settings/products/loan-types',
                        },
                        {
                            id: 'menu-level-saving',
                            title: 'Savings',
                            type: 'item',
                            url: '/settings/products/savings-types',
                        }
                    ]
                },
                {
                    id: 'settings-level',
                    title: 'Settings',
                    type: 'collapse',
                    icon: 'feather icon-settings',
                    children: [
                        {
                            id: 'general-settings',
                            title: 'General',
                            type: 'item',
                            url: '/settings/general',
                        },
                        {
                        id: 'users-role-page',
                        title: 'Users & Roles',
                        type: 'collapse',
                        children: [
                            {
                                id: 'users-main',
                                title: 'Users',
                                type: 'item',
                                url: '/settings/users',
                            },
                            {
                                id: 'settings-level-roles',
                                title: 'Roles',
                                type: 'item',
                                url: '/settings/roles',
                            }
                        ]
                    }]
                },
                {
                    id: 'standard-enties',
                    title: 'Standard Entries',
                    type: 'collapse',
                    icon: 'feather icon-sliders',
                    children: [
                        {
                            id: 'menu-level-currency',
                            title: 'Currency',
                            type: 'item',
                            url: '/settings/currency',
                        },
                        {
                            id: 'menu-level-funding',
                            title: 'Funding',
                            type: 'item',
                            url: '/settings/fundings',
                        },
                        {
                            id: 'menu-level-branches',
                            title: 'Branches',
                            type: 'item',
                            url: '/settings/branches',
                        },
                        {
                            id: 'menu-level-interest-type',
                            title: 'Interest Type',
                            type: 'item',
                            url: '/settings/interest-type',
                        },
                        {
                            id: 'menu-level-penalty-type',
                            title: 'Penalty Type',
                            type: 'item',
                            url: '/settings/penalty-type',
                        },
                        {
                            id: 'menu-level-payment-methods',
                            title: 'Payments Methods',
                            type: 'item',
                            url: '/settings/payment-methods',
                        },
                        {
                            id: 'menu-level-frequency',
                            title: 'Frequency Type',
                            type: 'item',
                            url: '/settings/frequency-type',
                        },
                        {
                            id: 'menu-level-witness-type',
                            title: 'Witness Type',
                            type: 'item',
                            url: '/settings/witness-type',
                        },
                        {
                            id: 'menu-level-document-type',
                            title: 'Documents Types',
                            type: 'item',
                            url: '/settings/document-type',
                        }
                    ]
                },
                {
                    id: 'report-menu',
                    title: 'Reports',
                    type: 'item',
                    url: '/reports',
                    icon: 'feather icon-file'
                },
                {
                    id: 'menu-level-calculator',
                    title: 'Loan Calculator',
                    type: 'item',
                    url: '/loan/sampleAmortization',
                    classes: 'nav-item',
                    icon: 'feather icon-hash'
                }
            ]
        }
    ]
}