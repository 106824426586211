import React from 'react';
import {NavLink} from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { connect } from "react-redux";

import Aux from "../../../hoc/_Aux";
import logoDark from '../../../assets/images/EmergeBank.png';

class ResetPassword1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          email: ""
        };
      }

    render () {
        return(
            <Aux>
                <div className="page-ath-wrap">
                    <div className="page-ath-content">
                        <div className="page-ath-header">
                            <a href="/" className="page-ath-logo"><img src={logoDark} alt="logo" /></a>
                        </div>
                        <div className="page-ath-form pt-0">
                        <h2 class="page-ath-heading">Reset password <span>If you forgot your password, well, then we’ll email you instructions to reset your password.</span></h2>
                                <ValidationForm autoComplete={false} onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}>
                                    <div className="input-item">
                                        <TextInput
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            type="email"
                                            required 
                                            errorMessage={{required:"Username is required"}}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            { 
                                                <Button type="submit" className="btn btn-primary btn-block">Send Reset Link</Button>
                                            }
                                        </div>
                                        <div>
                                            <NavLink to="/auth/signin" className="f-w-400"><strong>Return to login</strong></NavLink> 
                                        </div>
                                    </div>
                                    <div class="gaps-2x"></div>
                                </ValidationForm>
                        </div>

                        <div className="page-ath-footer">
                            <ul className="footer-links">
                                <li><a href="regular-page.html">Privacy Policy</a></li>
                                <li><a href="regular-page.html">Terms</a></li>
                                <li>&copy; 2020 Emergebanking.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="page-ath-gfx">
                        <div className="w-100 d-flex justify-content-center">
                            {/* <div className="col-md-8 col-xl-5"><img src={athGfx} alt="image" /></div> */}
                        </div>
                    </div>
                 </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
  };
  
  const mapDispatchToProps = dispatch => {

  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword1);