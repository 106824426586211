import Config from "../../../config";

const initState = {
  open: false,
  error: false,
  errorMsg: "",
  loading: false,
  loading_image: false,
  loadingText: "Processing Request...",
  meta: Config.defaultMeta,
  data: [],
  selectedClient: {},
  clientCreated: false,
  dropdownOpen: new Array(10).fill(false),
  clientAction: null,
  clientId: null,
  loanApplication: {
    loading: false,
    data: []
  },
  collaterals: {
    loading: false,
    data: []
  },
  guarateed: {
    loading: false,
    data: []
  },
  viewClientUri: "/clients/view-client/",
  clientLoanUri: "/clients/loan-applications/",
  clientCollateralUri: "/clients/client-collateral/",
  clientGuaranteeUri: "/clients/guaranted-loan/",
  clientCreation: {
    fileInput: null,
    pictureUrl: null,
    selectedDate: "",
    clientFormFields: {
      col1: [
        {
          name: "client.personalIdNumber",
          type: "text",
          validate: {
            required: { value: true },
            minLength: { value: 4 },
            maxLength: { value: 16 }
          }
        },
        { name: "client.firstName", type: "text", required: true },
        { name: "client.middleName", type: "text" },
        { name: "client.lastName", type: "text", required: true },
        { name: "client.phone", type: "text", required: true },
        { name: "client.clientTypeId", label: "Client Type", type: "select", required: true },
        { name: "client.externalId" },
      ],
      col2: [
        { name: "client.branchId", type: "text", label: "Branch Office", required: true },
        { name: "client.nationality", type: "text", label: "Nationality", required: true },
        { name: "client.email", type: "email" },
        { name: "client.gender", type: "select", label: "Gender", required: true },
        { name: "client.dateOfBirth", type: "date", required: true },
        { name: "client.clientClassificationId", label: "Client Classification", type: "select", required: true },
        { name: "client.isActive", type: "checkbox" },
      ]
    },
    addressFormFields:
    {
      addrCol1: [
        { name: "addresses.addressType", type: "select", label: "Address Type", required: true },
        { name: "addresses.address1", type: "textarea", required: true },
        { name: "addresses.address2", type: "textarea" },
        { name: "addresses.address3", type: "textarea" },
      ],
      addrCol2: [
        { name: "addresses.street", type: "text", required: true },
        { name: "addresses.countryCode", type: "text", label: "Country", required: true },
        { name: "addresses.countryFirstLevel", type: "select", label: "State / Region", required: true },
        { name: "addresses.city", type: "text", required: true },
        { name: "addresses.postalCode", type: "text", required: true },
        { name: "addresses.isActive", type: "hidden", value: true },
      ]
    }
  },
  client: {
    branchId: null,
    personalIdNumber: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    phone: null,
    nationality: null,
    gender: null,
    dateOfBirth: null,
    clientTypeId: null,
    clientClassificationId: null,
    externalId: null,
    isActive: null,
    primaryPhoto: null,
    submittedOn: null,
  },
  addresses: {
    street: null,
    addressType: null,
    address1: null,
    address2: null,
    address3: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
    isActive: null,
  }
};

export default function clients(state = initState, action) {
  switch (action.type) {
    case "FETCH_CLIENTS":
      return {
        ...state,
        error: false,
        data: action.payload,
        meta: action.meta,
        loading: false,
        loading_image: false
      };
    case "FETCH_A_CLIENT":
      return {
        ...state,
        error: false,
        selectedClient: {
          ...action.payload
        },
        clientId: action.clientId,
        loading: false,
        loading_image: false,
        viewClientUri: initState.viewClientUri.concat(action.clientId),
        clientLoanUri: initState.clientLoanUri.concat(action.clientId),
        clientCollateralUri: initState.clientCollateralUri.concat(action.clientId),
        clientGuaranteeUri: initState.clientGuaranteeUri.concat(action.clientId)
      };
    case "CLIENT_CREATED":
      return {
        ...state,
        error: false,
        selectedClient: {
          ...action.payload
        },
        clientCreated: true,
        loading: false,
        loading_image: false
      };
    case "CLIENT_UPDATED":
      return {
        ...state,
        error: false,
        clientCreated: true,
        loading: false,
        loading_image: false
      };
    case "CLIENT_LOADING":
      return {
        ...state,
        error: false,
        loading: true,
        loadingText: action.loadingMsg,
        loading_image: false
      };

    case "CLIENT_LOADING_STOP":
      return {
        ...state,
        error: false,
        loading: false,
        loading_image: false
      };


    case "ERROR_CLIENTS":
      return {
        ...state,
        error: true,
        errorMsg: action.errorMsg.messages,
        loading: false,
        loading_image: false
      };

    case "DISMISS_CLIENT_ERROR":
      return {
        ...state,
        error: false,
        errorMsg: "",
        loading: false,
        loading_image: false
      };

    case "LOADING_IMAGE":
      return {
        ...state,
        loading_image: true
      };

    case "CREATE_CLIENT_PICTURE_SELECTED":
      return {
        ...state,
        clientCreation: {
          ...state.clientCreation,
          fileInput: action.fileInput,
          pictureUrl: action.pictureUrl
        }
      }

    case "CLIENT_ADDRESS_CREATED":
      return {
        ...state,
        loading: false,
        clientAddressCreated: true
      };

    case 'SET_DROPDOWN_ACTION_LENGTH':
      return {
        ...state,
        dropdownOpen: new Array(action.menuLength).fill(false)
      };

    case 'UPDATE_CLIENT_ACTIONS_MENUS':
      return {
        ...state,
        dropdownOpen: action.toggle
      };

    case "DISMISS_ERROR":
    case "REFRESH_CLIENT":
      return initState;


    //for general notification 
    case "NOTIFICATION_DISSMISS":
      return {
        ...state,
        loading: false,
        loading_image: false
      }

    default:
      return state;
  }
}
