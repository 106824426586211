import React from 'react';
import $ from 'jquery';

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const Dashboard = React.lazy(() => import('./Containers/Dashboard/Dashboard'));

// client routes
const Clients = React.lazy(() => import('./Containers/Clients/Clients'));
const AddClients = React.lazy(() => import('./Containers/Clients/ClientsForm'));
const EditClients = React.lazy(() => import('./Containers/Clients/ClientsForm'));
const ViewClientDetails = React.lazy(() => import('./Containers/Clients/ViewClientDetails'));
const LoanApplications = React.lazy(() => import('./Containers/Clients/LoanApplications'));
const Collaterals = React.lazy(() => import('./Containers/Clients/Collaterals'));
const Guarantee = React.lazy(() => import('./Containers/Clients/Guarantee'));

// loans
const ActiveLoans = React.lazy(() => import('./Containers/Loans/ActiveLoans'));
// const ApplicaitionList = React.lazy(() => import('./Containers/Loans/ApplicaitionList'));

// branches
const Branches = React.lazy(() => import('./Containers/Branches/Branches'));
const ViewBranchDetails = React.lazy(() => import('./Containers/Branches/ViewBranchDetails'));

//Roles
const Roles = React.lazy(() => import('./Containers/Roles/Roles'));
const RolesDetails = React.lazy(() => import('./Containers/Roles/ViewRoleDetails'));

//Users
const Users = React.lazy(() => import('./Containers/Users/Users'));
const UsersDetails = React.lazy(() => import('./Containers/Users/ViewUserDetails'));

const Currency = React.lazy(() => import('./Containers/Currency/Currency'));
const CurrencyDetails = React.lazy(() => import('./Containers/Currency/CurrencyDetails'));

const Fundings = React.lazy(() => import('./Containers/Fundings/Fundings'));
const FundingsDetails = React.lazy(() => import('./Containers/Fundings/FundingsDetails'));

const InterestTypes = React.lazy(() => import('./Containers/InterestTypes/InterestTypes'));
const InterestTypeDetails = React.lazy(() => import('./Containers/InterestTypes/InterestTypeDetails'));

const WitnessTypes = React.lazy(() => import('./Containers/WitnessTypes/WitnessTypes'));
const WitnessTypeDetails = React.lazy(() => import('./Containers/WitnessTypes/WitnessTypeDetails'));

const PenaltyTypes = React.lazy(() => import('./Containers/PenaltyTypes/PenaltyTypes'));
const PenaltyTypeDetails = React.lazy(() => import('./Containers/PenaltyTypes/PenaltyTypeDetails'));

const PaymentMethods = React.lazy(() => import('./Containers/PaymentMethod/PaymentMethods'));
const PaymentMethodDetails = React.lazy(() => import('./Containers/PaymentMethod/PaymentMethodDetails'));

const FrequencyTypes = React.lazy(() => import('./Containers/FrequencyTypes/FrequencyTypes'));
const FrequencyTypeDetails = React.lazy(() => import('./Containers/FrequencyTypes/FrequencyTypeDetails'));

const DocumentTypes = React.lazy(() => import('./Containers/DocumentTypes/DocumentTypes'));
const DocumentTypeDetails = React.lazy(() => import('./Containers/DocumentTypes/DocumentTypeDetails'));

// /Product/Savings
const SavingsTypes = React.lazy(() => import('./Containers/Products/SavingsTypes'));
const SavingsTypesDetails = React.lazy(() => import('./Containers/Products/SavingsTypesDetails'));

const LoanTypes = React.lazy(() => import('./Containers/Products/LoanTypes'));
const LoanTypesDetails = React.lazy(() => import('./Containers/Products/LoanTypesDetails'));

const Savings = React.lazy(() => import('./Containers/Products/Savings'));
// const SavingsTypesDetails = React.lazy(() => import('./Containers/Products/SavingsTypesDetails'));

const Loans = React.lazy(() => import('./Containers/Products/Loans'));

const LoanForm = React.lazy(() => import('./Containers/LifeCyle/LoanForm'));
const NewSavings = React.lazy(() => import('./Containers/LifeCyle/NewSavings'));

const Amortizations = React.lazy(() => import('./App/components/LifeCycle/Amortizations'));
const SampleAmortization = React.lazy(() => import('./App/components/SampleAmortization'));

const Repayments = React.lazy(() => import('./Containers/LifeCyle/Repayments'));
const LoanStateHistories = React.lazy(() => import('./Containers/LifeCyle/LoanStateHistories'));
const SavingsStateHistories = React.lazy(() => import('./Containers/LifeCyle/SavingsStateHistories'));
const SavingsTransactions = React.lazy(() => import('./Containers/LifeCyle/SavingsTransactions'));
const Transactions = React.lazy(() => import('./Containers/LifeCyle/Transactions'));

// reports 
const Reports = React.lazy(() => import('./App/components/Reports'));

// general settings
const GeneralSettings = React.lazy(() => import('./Containers/GeneralSettings/SiteIdentity'));

const routes = [
    { path: '/', permissionId: "dashboard-page", exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/dashboard', permissionId: "dashboard-page", exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/clients', permissionId: "members-page", exact: true, name: 'Clients', component: Clients },
    { path: '/clients/create-clients', permissionId: "member-btn-create", exact: true, name: 'Add Clients', component: AddClients },
    { path: '/clients/view-client/:id', permissionId: 'member-btn-view', exact: true, name: 'View Client', component: ViewClientDetails },
    { path: '/clients/edit-client/:id', permissionId: "member-btn-edit", exact: true, name: 'View Client', component: EditClients },
    { path: '/clients/loan-applications/:id', permissionId: "member-btn-new-loan", exact: true, name: 'Loan Applications', component: LoanApplications },
    { path: '/clients/client-collateral/:id', exact: true, name: 'Collaterals', component: Collaterals },
    { path: '/clients/guaranted-loan/:id', exact: true, name: 'Guarantee', component: Guarantee },

    // General Settings
    { path: '/settings/general', permissionId: "general-settings", exact: true, name: 'View General Settings', component: GeneralSettings },

    // products - Savings
    { path: '/settings/products/savings/for-approval', permissionId: 'menu-level-savings-account-approve', exact: true, name: 'Savings', component: Savings, requestFilter: 'for-approval' },
    { path: '/settings/products/savings/for-update', permissionId: 'menu-level-savings-account-update', exact: true, name: 'For Update', component: Savings, requestFilter: 'for-update' },
    { path: '/settings/products/savings/pending-opening-balance', permissionId: 'menu-level-savings-account-initial', exact: true, name: 'Pending Opening Balance', component: Savings, requestFilter: 'pending-opening-balance' },
    { path: '/settings/products/savings/active', permissionId: 'menu-level-savings-account-update-application', exact: true, name: 'Active Savings', component: Savings, requestFilter: 'active' },
    { path: '/settings/products/savings/rejected', permissionId: 'menu-level-savings-account-rejected', exact: true, name: 'Rejected Savings', component: Savings, requestFilter: 'rejected' },
    { path: '/settings/products/savings/cancelled', permissionId: 'menu-level-savings-account-cancel', exact: true, name: 'Cancelled Savings', component: Savings, requestFilter: 'cancelled' },
    { path: '/settings/products/savings/closed', permissionId: 'menu-level-savings-account-close-account', exact: true, name: 'Closed Savings', component: Savings, requestFilter: 'closed' },

    // products - Loans
    { path: '/settings/products/loans/for-approval', permissionId: 'menu-level-for-approval', exact: true, name: 'Loans', component: Loans, requestFilter: 'for-approval' },
    { path: '/settings/products/loans/for-update', permissionId: 'menu-level-for-update', exact: true, name: 'Loans', component: Loans, requestFilter: 'for-update' },
    { path: '/settings/products/loans/active', permissionId: 'active-loan-page', exact: true, name: 'Loans', component: Loans, requestFilter: 'active' },
    { path: '/settings/products/loans/rejected', permissionId: 'menu-level-rejected', exact: true, name: 'Loans', component: Loans, requestFilter: 'rejected' },
    { path: '/settings/products/loans/cancelled', permissionId: 'menu-level-cancelled', exact: true, name: 'Loans', component: Loans, requestFilter: 'cancelled' },
    { path: '/settings/products/loans/settled', permissionId: 'menu-level-closed', exact: true, name: 'Loans', component: Loans, requestFilter: 'settled' },
    { path: '/settings/products/loans/pending-disbursement', permissionId: 'menu-level-pending-disbursement', exact: true, name: 'Loans', component: Loans, requestFilter: 'pending-disbursement' },

    // products - loan types
    { path: '/settings/products/loan-types', permissionId: 'menu-level-loan', exact: true, name: 'Savings Types', component: LoanTypes },
    { path: '/settings/products/loan-types/:id', permissionId: 'menu-level-loan', exact: true, name: 'Savings Type Details', component: LoanTypesDetails },

    // products - savings
    { path: '/settings/products/savings-types', permissionId: 'menu-level-saving', exact: true, name: 'Savings Types', component: SavingsTypes },
    { path: '/settings/products/savings-types/:id', permissionId: 'menu-level-saving', exact: true, name: 'Savings Type Details', component: SavingsTypesDetails },

    // settings - branches
    { path: '/settings/branches', permissionId: 'menu-level-branches', exact: true, name: 'Branches', component: Branches },
    { path: '/settings/branch/:id', permissionId: 'menu-level-branches', exact: true, name: 'View branch', component: ViewBranchDetails },

    // settings - roles
    { path: '/settings/roles', permissionId: 'settings-level-roles', exact: true, name: 'Roles', component: Roles },
    { path: '/settings/roles/add-role', permissionId: 'settings-level-roles-create', exact: true, name: 'View branch', component: RolesDetails },
    { path: '/settings/roles/:id', permissionId: 'settings-level-roles-edit', exact: true, name: 'View branch', component: RolesDetails },

    // settings - users
    { path: '/settings/users', permissionId: 'users-main', exact: true, name: 'Users', component: Users },
    { path: '/settings/users/add-user', permissionId: 'users-main-create', exact: true, name: 'Add user', component: UsersDetails },
    { path: '/settings/users/:id', permissionId: 'users-main-edit', exact: true, name: 'View user', component: UsersDetails },

    // setting - currency
    { path: '/settings/currency', permissionId: 'menu-level-currency', exact: true, name: 'Currency', component: Currency },
    { path: '/settings/currency/:id', permissionId: 'menu-level-currency', exact: true, name: 'View Currency', component: CurrencyDetails },

    // setting - funding
    { path: '/settings/fundings', permissionId: 'menu-level-funding', exact: true, name: 'Fundings', component: Fundings },
    { path: '/settings/fund/:id', permissionId: 'menu-level-funding', exact: true, name: 'View Fund', component: FundingsDetails },

    // setting - Interest Types - renamed to Interest
    { path: '/settings/interest-type', permissionId: 'menu-level-interest-type', exact: true, name: 'Interest Type', component: InterestTypes },
    { path: '/settings/interest-type/:id', permissionId: 'menu-level-interest-type', exact: true, name: 'Interest Type Details', component: InterestTypeDetails },

    // setting - Witness Types
    { path: '/settings/witness-type', permissionId: 'menu-level-witness-type', exact: true, name: 'Witness Type', component: WitnessTypes },
    { path: '/settings/witness-type/:id', permissionId: 'menu-level-witness-type', exact: true, name: 'Witness Type Details', component: WitnessTypeDetails },

    // setting - Panalty Types
    { path: '/settings/penalty-type', permissionId: 'menu-level-penalty-type', exact: true, name: 'Penalty Type', component: PenaltyTypes },
    { path: '/settings/penalty-type/:id', permissionId: 'menu-level-penalty-type', exact: true, name: 'Penalty Type Details', component: PenaltyTypeDetails },

    // setting - Payment Methods
    { path: '/settings/payment-methods', permissionId: 'menu-level-payment-methods', exact: true, name: 'Payment Method', component: PaymentMethods },
    { path: '/settings/payment-methods/:id', permissionId: 'menu-level-payment-methods', exact: true, name: 'Payment Method Details', component: PaymentMethodDetails },

    // setting - Frequency Types
    { path: '/settings/frequency-type', permissionId: 'menu-level-frequency', exact: true, name: 'Frequency Type', component: FrequencyTypes },
    { path: '/settings/frequency-type/:id', permissionId: 'menu-level-frequency', exact: true, name: 'Frequency Type Details', component: FrequencyTypeDetails },

    // setting - Document Types
    { path: '/settings/document-type', permissionId: 'menu-level-document-type', exact: true, name: 'Document Type', component: DocumentTypes },
    { path: '/settings/document-type/:id', permissionId: 'menu-level-document-type', exact: true, name: 'Document Type Details', component: DocumentTypeDetails },


    // Loans
    { path: '/loan/active', permissionId: 'active-loan-page', exact: true, name: 'Active Loans', component: ActiveLoans },
    // { path: '/loan/applications', exact: true, name: 'Loan Applications', component: ApplicaitionList },

    // Load loan form / adding and viewing
    { path: '/clients/:id/:status', permissionId: 'member-btn-new-loan', exact: true, name: 'Loan Application', component: LoanForm },
    { path: '/loan/amortizations', permissionId: 'member-btn-view-amortizations', exact: true, name: 'Loan Application', component: Amortizations },
    { path: '/loan/sampleAmortization', exact: true, name: 'Loan Application', component: SampleAmortization },
    { path: '/loan/repayment/:id', permissionId: 'member-btn-repayment', exact: true, name: 'Loan Repayment', component: Repayments },
    { path: '/loan/:id/histories', exact: true, name: 'Loan Histories', component: LoanStateHistories },

    // reports generation
    { path: '/reports', permissionId: "report-menu", exact: true, name: 'Reports', component: Reports },

    // Loan savings form
    { path: '/clients/savings/:id/:status', permissionId: 'member-btn-new-savings', exact: true, name: 'Savings Application', component: NewSavings },
    { path: '/savings/:id/histories', exact: true, name: 'Savings Histories', component: SavingsStateHistories },
    { path: '/savings/:id/transactions', exact: true, name: 'Savings Transactions', component: SavingsTransactions },
    { path: '/products/savings/list/transactions', permissionId: 'menu-level-savings-transaction', exact: true, name: 'Savings Transaction List', component: Transactions },

];

export default routes;