import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedWitnessType: {
        name: "",
        description: "",
        updatedAt: ""
    },
    witnessTypeCreated: false,
    witnessTypeAction: null,
    witnessTypeId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function witness_types(state = initState, action) {

    switch (action.type) {

        case 'FETCH_WITNESS_TYPES':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_WITNESS_TYPE":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedWitnessType: {
                    ...action.payload
                },
                witnessTypeId: action.witnessTypeId,
            };

        case "WITNESS_TYPE_CREATED":
            return {
                ...state,
                error: false,
                selectedWitnessType: {
                    ...action.payload
                },
                witnessTypeCreated: true,
                loading: false,
                loading_image: false
            };


        case "WITNESS_TYPES_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false,
                selectedWitnessType: {
                    ...action.payload
                }
            };

        case 'ERROR_WITNESS_TYPE':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "WITNESS_TYPES_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "WITNESS_TYPES_LIST_DEFAULT":
        case "CLEAR_WITNESS_TYPES_DATA":
        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}