import Jsona from "jsona";
import http from "../api/http";
import { showNotification } from "./smfi/notification";



export function fetchAllCountries() {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_COUNTRIES_URL;

    const request = http.get(apiBaseUrl);

    const dataFormatter = new Jsona();

    return dispatch => {
        dispatch(setCountriesLoading("Loading.."))

        request.then(
            ({ data }) => {

                let res = dataFormatter.deserialize(data);

                dispatch({
                    type: "FETCH_COUNTRIES",
                    data: res,
                });
            },
            error => {
                console.log("Error Request", error);

                dispatch(showNotification({ type: "error", message: error.messages }));
            }
        );
    };
}



export function fetch1stLevelByCountry(strCountryCode = process.env.REACT_APP_DEFAULT_COUNTRY) {

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_COUNTRIES_URL;

    apiBaseUrl += '/' + strCountryCode + process.env.REACT_APP_COUNTRIES_1ST_LVEL_URI_SUFFIX

    const request = http.get(apiBaseUrl);

    const dataFormatter = new Jsona();

    return dispatch => {
        dispatch(setCountriesFirstLevelLoading("Loading.."))

        request.then(
            ({ data }) => {

                let res = dataFormatter.deserialize(data);

                dispatch({
                    type: "FETCH_FIRST_LEVEL_COUNTRIES",
                    data: res,
                });
            },
            error => {
                console.log("Error Request", error);

                dispatch(showNotification({ type: "error", message: error.messages }));
            }
        );
    };
}




export function setCountriesLoading(loadingMsg) {
    return (dispatch) => {
        dispatch({
            type: 'COUNTRIES_LOADING',
            loadingMsg: loadingMsg
        })
    }
}


export function setCountriesFirstLevelLoading(loadingMsg) {
    return (dispatch) => {
        dispatch({
            type: 'COUNTRIES_FIRST_LEVEL_LOADING',
            loadingMsg: loadingMsg
        })
    }
}

export function defaultCountries() {
    return dispatch => {
        dispatch({
            type: "CLEAR_ADDRESS_DATA"
        });
    };
}

export function dismissError() {
    return (dispatch) => {
        dispatch({ type: 'DISMISS_ERROR' });
    }
}