import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: true,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    interestCalculationList: [],
    selectedLoanTypes: {
        name: "",
        description: "",
        updatedAt: ""
    },
    loanTypesCreated: false,
    loanTypesAction: null,
    loanTypesId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function loan_types(state = initState, action) {

    switch (action.type) {

        case 'FETCH_LOAN_TYPES':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_LOAN_TYPES":
            return {
                ...state,
                error: false,
                loading: false,
                loading_image: false,
                selectedLoanTypes: {
                    ...action.payload
                },
                loanTypesId: action.loanTypesId,
            };

        case "LOAN_TYPES_CREATED":
            return {
                ...state,
                error: false,
                selectedLoanTypes: {
                    ...action.payload
                },
                loanTypesCreated: true,
                loading: false,
                loading_image: false
            };


        case "LOAN_TYPES_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false,
                selectedLoanTypes: {
                    ...action.payload
                }
            };

        case 'ERROR_LOAN_TYPES':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "LOAN_TYPES_LOADING":
            return {
                ...state,
                error: false,
                loading: action.boolIsLoading,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "LOAN_TYPES_LIST_DEFAULT":
        case "CLEAR_LOAN_TYPES_DATA":
        case "DISMISS_ERROR":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}