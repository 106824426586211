import Config from '../../../config';

const initState = {
    open          : false,
    error         : false,
    errorMsg      : "",
    loading       : false,
    loading_image : false,
    loadingText   : "Processing Request...",
    meta          : Config.defaultMeta,
    data          : [],
    selectedFund: {
        amount      : 0,
        description : "",
        funding_date: "",
        branchId    : "",
        paymentType : ""
    },
    fundCreated: false,
    fundAction : null,
    fundingId  : null,
    defaultSorted: [
        {
            id  : "createdAt",
            desc: true
        }
    ]
}

export default function fundings(state = initState, action) {

    switch (action.type) {

        case 'FETCH_FUNDINGS':
            return {
                ...state,
                error        : false,
                data         : action.data,
                meta         : action.meta,
                loading      : false,
                loading_image: false
            };

        case "FETCH_A_FUNDING": 
            return {
              ...state,
              error         : false,
              selectedFund: {
                ...action.payload
              },
              fundingId          : action.fundingId,
              loading            : false,
              loading_image      : false
            };

        case "FUNDING_CREATED": 
            return {
                ...state,
                error       : false,
                selectedFund: {
                    ...action.payload
                },
                fundCreated  : true,
                loading      : false,
                loading_image: false
            };

        
        case "FUNDING_UPDATED": 
            return {
                ...state,
                error        : false,
                updatedreated: true,
                loading      : false,
                loading_image: false,
                selectedFund: {
                    ...action.payload
                }
            };

        case 'ERROR_FUNDINGS':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "FUNDINGS_LOADING":
            return {
                ...state,
                error        : false,
                loading      : true,
                loadingText  : action.loadingMsg, 
                loading_image: false
            }

        case "FUNDING_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_FUNDING_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}