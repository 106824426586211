import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedMethod: {
        methodName: "",
        displayName: "",
        description: ""
    },
    methodCreated: false,
    methodAction: null,
    methodId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ]
}

export default function payment_methods(state = initState, action) {

    switch (action.type) {

        case 'FETCH_PAYMENT_METHOD':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_PAYMENT_METHOD":
            return {
                ...state,
                error: false,
                selectedMethod: {
                    ...action.payload
                },
                methodId: action.methodId,
                loading: false,
                loading_image: false
            };

        case "PAYMENT_METHOD_CREATED":
            return {
                ...state,
                error: false,
                selectedMethod: {
                    ...action.payload
                },
                methodCreated: true,
                loading: false,
                loading_image: false
            };

        case "PAYMENT_METHOD_LOADING":
            return {
                ...state,
                error: false,
                loading: true,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "PAYMENT_METHOD_UPDATED":
            return {
                ...state,
                error: false,
                updated: true,
                loading: false,
                loading_image: false
            };

        case 'ERROR_PAYMENT_METHOD':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "PAYMENT_METHOD_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_PAYMENT_METHOD_DATA":
            return initState


        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }
}