import Config from '../../../config';

const initState = {
    open: false,
    error: false,
    errorMsg: "",
    loading: false,
    loading_image: false,
    loadingText: "Processing Request...",
    meta: Config.defaultMeta,
    data: [],
    selectedBranch: {
        branchCode: "",
        branchLocation: "",
        branchName: "",
        address: "",
        createdAt: "",
        description: "",
        type: "",
        updatedAt: ""
    },
    branchCreated: false,
    branchAction: null,
    branchId: null,
    defaultSorted: [
        {
            id: "createdAt",
            desc: true
        }
    ],
    dropdownOpen: new Array(10).fill(false)
}

export default function branches(state = initState, action) {

    switch (action.type) {

        case 'FETCH_BRANCHES':
            return {
                ...state,
                error: false,
                data: action.data,
                meta: action.meta,
                loading: false,
                loading_image: false
            };

        case "FETCH_A_BRANCH":
            return {
                ...state,
                error: false,
                selectedBranch: {
                    ...action.payload
                },
                branchId: action.branchId,
                loading: false,
                loading_image: false
            };

        case "BRANCH_CREATED":
            return {
                ...state,
                error: false,
                selectedBranch: {
                    ...action.payload
                },
                branchCreated: true,
                loading: false,
                loading_image: false
            };


        case "BRANCH_UPDATED":
            return {
                ...state,
                error: false,
                updatedreated: true,
                loading: false,
                loading_image: false
            };

        case 'ERROR_BRANCHES':
            return {
                ...state,
                error: true,
                errorMsg: action.errorMsg.messages,
                loading: false,
                loading_image: false
            };

        case "BRANCHES_LOADING":
            return {
                ...state,
                error: false,
                loading: true,
                loadingText: action.loadingMsg,
                loading_image: false
            }

        case "BRANCH_LIST_DEFAULT":
        case "DISMISS_ERROR":
        case "CLEAR_BRANCH_DATA":
            return initState

        //for general notification 
        case "NOTIFICATION_DISSMISS":
            return {
                ...state,
                loading: false,
                loading_image: false
            }

        default:
            return state

    }

}