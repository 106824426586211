import axios from 'axios'
import Jsona from 'jsona';

import http from '../../api/http'
import Config from '../../../config'

// todo!! use new notification design
// import { showNotification } from './notification'

const strGeneralError = 'There was a problem in connecting to the server. Please check your internet and try again.';

const defaultParams = {
    include: Config.REACT_APP_PROFILE_INCLUDE
}

export function getAuthHeader(boolAuthHeaderOnly = false) {
    if (localStorage.getItem('smfisession')) {
        let authSession = JSON.parse(localStorage.getItem('smfisession'));
        if (!boolAuthHeaderOnly) {
            let request = {
                headers: {
                    'Authorization': 'Bearer ' + authSession.accessToken,
                }
            };

            return request;
        } else {

            let request = 'Bearer ' + authSession.accessToken;

            return request;
        }

    } else {
        return null;
    }
};

export function postLogin(objCredentials) {
    //first check if there is an existing login for the user 
    if (localStorage.getItem('smfisession') &&
        localStorage.getItem('smfiuserinfo')) {
        let userInfo = localStorage.getItem('smfiuserinfo')
        let {
            firstName,
            lastName
        } = JSON.parse(userInfo)
        // console.log(userInfo)

        let strDetail = (firstName) ? (' as ' + firstName + ' ' + lastName) : ''

        alert('You are already logged-in as ' + strDetail + '.   This window/tab will refresh to use that login.')

        // return (dispatch) => {
        //     dispatch({ type: 'RELOAD_WINDOW' })
        // }

    }

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_IDENTITY_LOGIN_URI;

    let objRequest = {
        data: {
            type: 'token',
            attributes: {
                ...objCredentials,
                clientid: process.env.REACT_APP_CLIENT_ID
            }
        }
    }

    // console.log('postLogin', objRequest);
    const request = axios.post(apiBaseUrl, objRequest)

    return (dispatch) => {

        return request.then(({
            data
        }) => {

            // console.log('Login successful, fetching user..');

            const dataFormatter = new Jsona();
            let res = dataFormatter.deserialize(data)

            dispatch(fetchCurrUser(res))

        },
            (error) => {

                //simply check type of error message
                if (error.response) {
                    // console.log(error.response.data);
                    // console.log(error.response.data.errors)
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    dispatch({
                        type: 'AUTH_FAILED',
                        errorMsg: error.response.data.errors.message
                    })
                } else {
                    console.log('Error Request', error)
                    dispatch({
                        type: 'AUTH_FAILED',
                        errorMsg: strGeneralError
                    })
                }
            });
    }
}

export function fetchCurrUser(objAccess) {
    //store the value first...
    localStorage.setItem('smfisession', JSON.stringify(objAccess));

    let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_USER_URI;

    apiBaseUrl += objAccess.id

    let params = {
        params: {
            ...defaultParams
        }
    }

    // console.log("defaultParams", params);

    const request = http.get(apiBaseUrl, params)

    const dataFormatter = new Jsona();

    return (dispatch) => {
        request.then(({
            data
        }) => {

            let user = dataFormatter.deserialize(data)
            // console.log('current user data', user);

            //store the user info to get reloading correctly 
            localStorage.setItem('smfiuserinfo', JSON.stringify(user));

            //dispatch
            dispatch({
                type: 'LOGIN',
                user: user,
                auth: objAccess
            })

        },
            (error) => {

                //simply check type of error message
                console.log('Error Request', error)
                dispatch({
                    type: 'AUTH_FAILED',
                    errorMsg: strGeneralError
                })
            });
    }

}

export function loadAuthUserFromSession() {
    //simply fill auth reducer from storage
    let objSession = JSON.parse(localStorage.getItem('smfisession'))
    let objUserData = JSON.parse(localStorage.getItem('smfiuserinfo'))

    return (dispatch) => {
        dispatch({
            type: "AUTH_LOAD_SESSION",
            user: objUserData,
            auth: objSession
        })
    }
}

export function postLogout() {
    return (dispatch) => {
        dispatch({
            type: 'LOGOUT'
        })
    }
}

export function setLoading() {
    return (dispatch) => {
        dispatch({
            type: 'AUTH_LOADING'
        })
    }
}

export function dismissAuthError() {
    return (dispatch) => {
        dispatch({
            type: 'DISMISS_AUTH_ERROR'
        })
    }
}
