import Jsona from "jsona";
import http from "../../api/http";
import Config from "../../../config";

import { createClientAddress, updateClientAddress } from "./addresses";
import { showNotification } from "./notification";

import { reactTableFilters, reactTableSort } from "../utils";

import Resizer from "react-image-file-resizer";

const strGeneralError =
  "There was a problem in connecting to the server. Please check your internet and try again.";

export function dismissClientError() {
  return (dispatch) => {
    dispatch({ type: 'DISMISS_ERROR' });
  }
}

export function setClientsLoading(loadingMsg) {
  return (dispatch) => {
    dispatch({
      type: 'CLIENT_LOADING',
      loadingMsg: loadingMsg
    })
  }
}

export function fetchClients(state, instance, clientId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_URI;

  let params = state
    ? {
      page: {
        size: state.pageSize,
        number: state.page + 1
      },
      sort: reactTableSort(state).pop(),
      filter: reactTableFilters(state)
    }
    : {
      ...Config.defaultMeta
    };

  params.module = 'clients';

  const request = http.get(apiBaseUrl, {
    params
  });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);

        dispatch({ type: "FETCH_CLIENTS", meta: data.meta, payload: res });

        dispatch(setClientDropdownActionLength(data.meta.itemTotal));

      },
      error => {
        if (
          String(error) ===
          "TypeError: Cannot read property 'parseError' of undefined"
        ) {
          dispatch({ type: "CLIENT_LOADING_STOP" });
        } else {
          dispatch({
            type: "ERROR_CLIENTS",
            errorMsg: strGeneralError
          });
        }
      }
    );
  };
}

export function fetchAClient(clientId) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_URI;
  apiBaseUrl += clientId

  let params = {
    params: {
      include: Config.REACT_APP_CLIENTS_INCLUDE
    }
  }

  const request = http.get(apiBaseUrl, { params });

  const dataFormatter = new Jsona();

  return dispatch => {
    request.then(
      ({ data }) => {

        let res = dataFormatter.deserialize(data);
        // console.log('fetchAClient', res)
        dispatch({ type: "FETCH_A_CLIENT", payload: res[0], clientId: clientId });

      },
      (error) => {
        dispatch(showNotification({ type: 'error', message: error }));
        dispatch({ type: 'ERROR_CLIENTS', errorMsg: strGeneralError })
      }
    );
  };

}

export function createClient(objFormValues) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_URI;

  const addresses = { ...objFormValues.addresses };

  let params = {
    data:
    {
      type: "Client",
      attributes:
      {
        ...objFormValues.client
      }
    }
  }

  const request = http.post(apiBaseUrl, params)

  const dataFormatter = new Jsona();

  return (dispatch) => {
    request.then(({
      data
    }) => {

      let res = dataFormatter.deserialize(data)

      dispatch({ type: 'CLIENT_CREATED', payload: res })
      dispatch(setClientsLoading("Creating client address..."));

      addresses.clientId = res.id;
      dispatch(createClientAddress(addresses, true));

      if (objFormValues.fileInput) {
        dispatch(setClientsLoading("Uploading client photo..."));
        dispatch(uploadClientProfilePic(res.id, objFormValues.fileInput));
      }

      dispatch(showNotification({ type: "success", message: "Client has successfully been created!" }));

    },
      (error) => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({
          type: 'ERROR_CLIENTS',
          errorMsg: error
        })
      });
  }
}


export function editClient(objFormValues) {

  let apiBaseUrl = process.env.REACT_APP_IDENTITY_BASE_URL + process.env.REACT_APP_CLIENTS_URI;

  let { clientId, addressId } = objFormValues
  apiBaseUrl += clientId

  const addresses = { ...objFormValues.addresses };

  let params = {
    data:
    {
      type: "Client",
      attributes:
      {
        ...objFormValues.client
      }
    }
  }

  const request = http.put(apiBaseUrl, params)

  // const dataFormatter = new Jsona();

  return (dispatch) => {

    addresses.clientId = clientId;
    addresses.addressId = addressId
    dispatch(updateClientAddress(addresses));

    request.then(({
      data
    }) => {

      // let res = dataFormatter.deserialize(data)

      
      dispatch(setClientsLoading("Creating client address..."));

      if (objFormValues.fileInput) {
        dispatch(setClientsLoading("Uploading client photo..."));
        dispatch(uploadClientProfilePic(clientId, objFormValues.fileInput));
      }

      dispatch(showNotification({ type: "success", message: "Client has successfully been updated!" }));

    },
      (error) => {
        dispatch(showNotification({ type: "error", message: error }));
        dispatch({
          type: 'ERROR_CLIENTS',
          errorMsg: error
        })
      });
  }
}


export function setClientDropdownActionLength(menuLength) {
  return dispatch => {
    dispatch({
      type: "SET_DROPDOWN_ACTION_LENGTH",
      menuLength: menuLength
    });
  };
}

export function toggleClientActions(toggle) {
  return dispatch => {
    dispatch({
      type: "UPDATE_CLIENT_ACTIONS_MENUS",
      toggle: toggle
    });
  };
}

export function prepareProfilePicUpload(picture) {
  return dispatch => {

    Resizer.imageFileResizer(
      picture[0],
      300,
      300,
      "JPEG",
      100,
      0,
      uri => {
        fetch(uri)
          .then(res => res.blob())
          .then(blob => {
            let inputFile = new File([blob], picture[0].name, {
              type: "image/jpeg"
            });

            let fileInput = [];
            fileInput.push(inputFile);

            dispatch({
              type: "CREATE_CLIENT_PICTURE_SELECTED",
              fileInput,
              pictureUrl: URL.createObjectURL(inputFile)
            });

          });
      },
      "base64"
    );


  };
}


export function uploadClientProfilePic(clientId, picture) {

  let apiBaseUrl =
    process.env.REACT_APP_IDENTITY_BASE_URL +
    process.env.REACT_APP_CLIENTS_IMAGES_URI;

  apiBaseUrl += clientId;

  var formData = new FormData();
  // console.log(picture)
  formData.append("profileImage", picture.pop());

  http.interceptors.request.use(config => {
    config.headers = { "Content-Type": "multipart/form-data" };

    return config;
  });

  const request = http.post(apiBaseUrl, formData);

  return dispatch => {
    request.then(
      ({ data }) => {
        dispatch(fetchAClient(clientId));
      },

      error => {
        dispatch({ type: "ERROR_CLIENTS", errorMsg: error });
      }
    );
  };
}